export const banner = require("./banner.png");
export const logo = require("./main-logo.png");
export const logoPay = require("./logo/logo.png");
export const logo2 = require("./logo/duolingo.png");
export const logo3 = require("./logo/notion.png");
export const logo4 = require("./logo/ted.png");
export const logo5 = require("./logo/Vector.png");
export const logo6 = require("./logo/walmart.png");
export const logo7 = require("./logo/crunchbase.png");
export const noLogo = require("./Group 51.png");
export const listIcon = require("./list.png");
export const select = require("./18 1.png");
export const contact = require("./contact.png");
export const map = require("./mapillustration 11.png");
export const tick = require("./tickillustration 9.png");
export const maili = require("./mailillustration 7.png");
export const files = require("./fileillustration 6.png");
export const dislike = require("./dislikeillustration 5.png");
export const analysis = require("./analaillustration 10.png");
export const setting = require("./settillustration 4.png");
export const tshirticon = require("./tshirtillustration 8.png");
export const editIcon = require("./edit.png");
export const logos = require("./owl-logo2.png");
export const head = require("./Invoice-head.jpg");
export const tshirt1 = require("./tshirt-1.jpg");

//giveback starts
export const gb_banner = require("./gb_banner.png");
export const our_gb1 = require("./our-gb1.png");
export const our_gb2 = require("./our-gb2.png");
export const our_gb3 = require("./gb-3.png");
export const gb_foot = require("./gb_foot.png");
export const blog_ban = require("./blog-banneruser 2.png");
export const blog_user2 = require("./blog-1 1.png");
export const blog_loop = require("./loop.png");

// export const dashboard = require("./dashboard.png");
export const sustainability = require("./sustainability.png");
export const dashh1 = require("./dash 1.png");
export const dashh2 = require("./dash 2.png");
export const dashh3 = require("./dash 3.png");
export const dashh4 = require("./user 2.png");

export const dashboard = require("./dashboard.png");
export const exclusive = require("./exclusive.png");
export const sendMerch = require("./back1.png");
export const clinet1 = require("./client.png");
export const distribBack = require("./back2.png");
export const back3 = require("./back3.png");
// export const support = require("./support-img.png");
export const support = require("./support1.png");
export const supportPhone = require("./mobile.png");
export const sadEmoj = require("./sad-emoji.png");
export const errorWarn = require("./error-warning.png");
export const userIcon = require("./user.png");
export const profileIcon = require("./profile_icon.png");
export const billingIcon = require("./billing_icon.png");
export const companyIcon = require("./company_icon.png");
export const membershipIcon = require("./membership_icon.png");
export const uploadIcon = require("./upload_icon.png");
export const upload1 = require("./upload1.png");
export const rocketIcon = require("./rocket_icon.png");
export const noteIcon = require("./note_icon.png");
export const downArrow = require("./down-arrow.png");
//tick
export const ticker = require("./tick.png");
export const p1 = require("./p1.webp");
export const p2 = require("./p2.webp");
export const p3 = require("./p3.jpg");
export const p4 = require("./p4.webp");
//search
export const Searcher = require("./Search.png");
export const starYellow = require("./star.png");
export const happyEmoj = require("./happy-emoji.png");
export const logist = require("./logistics.png");
export const groupKits = require("./group_of_kits.png");
export const pacImg = require("./small_pack.png");
export const serviceBack = require("./service-back.png");
export const serv1 = require("./serv1.png");
export const img1 = require("./img1.png");
export const bg = require("./bg.png");
export const bg1 = require("./elip1.png");
export const fb = require("./fb.png");
export const Lin = require("./Lin.png");
export const sergio = require("./Mask group.png");
export const raquel = require("./raquel.png");
export const owllogo = require("./owl-logo2 31.png");
export const google = require("./google.png");
export const twitter = require("./twitter.png");
export const login = require("./login.png");
export const loginban = require("./welcome_ban.png");
export const kit1 = require("./group_of_kits 1.png");
export const kit2 = require("./group_of_kits 2.png");
export const kit3 = require("./group_of_kits 3.png");
export const echo = require("./echo-brand.png");
export const ninoslogo = require("./ninos-logoo 1.png");
export const forerun = require("./Forerun Logo -white 1.png");

//product-start
export const design = require("./design_pack.png");
export const preset = require("./preset_kit.png");
export const bulk = require("./bulk_merchandise.png");
export const blank = require("./buy_a_blank.png");
export const ecommerce = require("./ecommerce.png");
export const build_Pack = require("./build_pack.png");
export const abt_Pack = require("./abtbuild_pack.png");
export const sus_Pack = require("./susGroup-194.png");
export const giv_back = require("./giveback.png");
export const blog_Pack = require("./blogGroup-267.png");
export const faq_Pack = require("./faqbulk_pack.png");
export const presetPack = require("./preset_pack.png");
export const bulkPack = require("./bulk_pack.png");
export const buildImg = require("./build.png");
export const presetImg = require("./preset.png");
export const bulkImg = require("./bulk.png");
// export const inovFut = require("./innovate_future.png");
export const inovFut = require("./innovate_future1.png");
export const closeIcon = require("./close.png");
export const product1 = require("./product_1.png");
export const productd = require("./product_2_ong 3.png");
export const product2 = require("./product_2.png");
export const delivery_product = require("./delivery_1.png");
export const cloudUpload = require("./bx-cloud-upload.png");
export const shippmentIcon = require("./shippment.png");
//product-start
//company-start
export const ourTeam = require("./our-team.png");
export const ourstory = require("./merchHQ_Illustration2_render 1.png");
export const meetmhq = require("./meet-mhq.png");
export const circle = require("./Ellipse 11.png");
export const circle1 = require("./circle4.png");
export const circle2 = require("./circle3.png");
export const circle3 = require("./circle2.png");
export const circle4 = require("./circle1.png");
export const circle5 = require("./cirlce5.png");
export const abt1 = require("./abt1.jpg");
export const abt2 = require("./abt2.jpg");
export const abt3 = require("./abt3.jpg");
export const abt4 = require("./abt4.jpg");
export const abt5 = require("./abt5.jpg");
export const abt6 = require("./abt6.jpg");
export const abt7 = require("./abt7.jpg");
export const abt8 = require("./abt8.jpg");
export const abt9 = require("./abt9.jpg");
export const abt10 = require("./abt10.jpg");
export const abt11 = require("./abt11.jpg");
export const abt12 = require("./abt12.jpg");

export const inst1 = require("./instructor01.png");
export const inst2 = require("./instructor02.png");
export const inst3 = require("./instructor03.png");
export const inst4 = require("./instructor04.png");
export const inst5 = require("./instructor05.png");
export const inst6 = require("./instructor06.png");
export const inst7 = require("./instructor07.png");
export const missionIcon = require("./our_vision_icon.png");
export const vissionIcon = require("./our_mission_icon.png");
export const teamIcon = require("./team_circle.png");
export const person1 = require("./person.png");
export const ourTeamBack = require("./our-team-back.jpg");
export const joinTeam = require("./join_our_img.png");
export const stayProd = require("./stay_prod.png");
export const Rectangle = require("./Rectangle.png");
export const Vector = require("./Vector.png");
export const Messager = require("./Group 578.png");
//company-end

//faq-start
export const faq = require("./faq_back.png");
export const visitImg = require("./visit.png");
export const searchIcon = require("./bx-search-alt.png");
export const faqImg = require("./faq_img.png");
//faq-end

//member-ship-start
export const checkIcon = require("./green-check.png");
export const diamondIcon = require("./diamond.png");
export const cancelIcon = require("./red-cancel.png");
export const moneyICon = require("./money.png");
export const work1 = require("./work1.png");
export const work2 = require("./work2.png");
export const work3 = require("./work3.png");
export const internationalShippment = require("./international_shippment.png");
export const samplePack = require("./the_sample_pack 1.png");
export const emailIcon = require("./email.png");
export const letsWorkIcon = require("./lets-work.png");
export const SpecialCateg = require("./discount.png");
export const product = require("./product_detail_img.png");
export const dash1 = require("./dashboard 1.png");
export const dash2 = require("./dashboard 2.png");
export const dash3 = require("./dashboard 3.png");
export const dash4 = require("./dashboard 4.png");
export const dash5 = require("./dashboard 5.png");
export const whatsNew = require("./whats_new.png");
export const filterAlt = require("./bx-filter-alt.png");
export const ship1 = require("./ship1.png");
export const ship2 = require("./ship2.png");
export const discountIcon = require("./discount_icon.png");
export const commentIcon = require("./comment.png");
export const quickIcon = require("./quick.png");
export const empty_redeem = require("./empty_redeem.webp");
export const bx_filter = require("./bx-filter-alt.png");
export const link_icon = require("./linkicon.png");

//member-ship-end

// Redeem
export const redeem_animated = require("./redeem_animated_img.webp");
export const autoship = require("./autoship.webp");
export const cogwheel = require("./cogwheel.png");
export const file = require("./file.png");
export const light_mode = require("./light_mode.png");
export const night_mode = require("./night_mode.png");
export const redeem_collection = require("./redeem_collection.webp");

// Number Icons

export const numebr_two = require("./iconTwo.png");
export const numebr_three = require("./iconThree.png");

// Blog bg

export const blog_bg = require("./blog_bg.png");
export const blog_tip = require("./blog-tip.jpg");
export const blog_swag = require("./blog-swag.jpg");

// Services
export const service_banner = require("./services_banner.png");
export const our_process = require("./our-process-banner.png");
//chat
export const Message = require("./message.png");
// About Us
export const cloudIcon = require("./cloud-icon.png");
export const globeLinesIcon = require("./globe-lines-icon.png");
export const handHeartIcon = require("./hand-heart-icon.png");
export const partyPopperIcon = require("./party-popper-icon.png");
export const pinkHeartIcon = require("./pink-heart-icon.png");
export const robotIcon = require("./robot-icon.png");
export const telescopeIcon = require("./telescope-icon.png");
export const vrIcon = require("./vr-icon.png");
export const colorPanelIcon = require("./color-panel-icon.png");
export const cardboardboxIcon = require("./cardboardbox-icon.png");
export const globeShipIcon = require("./globe-ship-icon.png");
export const dashboardIcon = require("./dashboard-icon.png");
export const securityIcon = require("./security-icon.png");
export const redBagIcon = require("./red-bag-icon.png");
export const callUsIcon = require("./callus-icon.png");

//catef
export const pack = require("./categ/icon1.png");
export const waterBottle = require("./categ/water-bottle.png");
export const tumbler = require("./categ/tumbler.png");
export const tshirt = require("./categ/tshirt.png");
export const poloShirt = require("./categ/polo-shirt.png");
export const hoodies = require("./hoodies_package.png");
export const jackets = require("./jackets_package (1).png");
export const notebooks = require("./notebok_package (2).png");
export const backpack = require("./backpack_package (3).png");
export const totes = require("./total_package (4).png");
export const travel = require("./travel_package (5).png");
export const gadgets = require("./gadgets_package (6).png");
export const accesories = require("./accessories_package (7).png");
export const shift = require("./shift_package (8).png");
export const home = require("./Home_package (9).png");
export const fitness = require("./fitness_package (10).png");
export const snakables = require("./snackables.png");
export const kit = require("./kit.png");
export const packaging = require("./packaging (1).png");
export const events = require("./events package (11).png");
export const caps = require("./caps_package (3).png");
export const tshirts = require("./tsirts_package (2).png");
export const discount = require("./discount 2.png");
export const packke = require("./pack.png");

export const cat1 = require("./categ/1.jpg");
export const cat2 = require("./categ/2.jpg");
export const cat3 = require("./categ/3.jpg");
export const cat4 = require("./categ/4.jpg");
export const cat4i1 = require("./categ/4-1.jpg");
export const cat4i2 = require("./categ/4-2.jpg");
export const cat4i3 = require("./categ/4-3.jpg");
export const cat5 = require("./categ/5.jpg");

// Carocle images
export const products1 = require("./produces1.png");
export const products2 = require("./produces2.png");
export const products3 = require("./produces3.png");
export const products4 = require("./produces4.png");

export const servPlat1 = require("./service/1.png");
export const servPlat2 = require("./service/2.png");
export const servPlat3 = require("./service/3.png");
export const servPlat4 = require("./service/4.png");
export const servPlat5 = require("./service/5.png");
export const servPlat6 = require("./service/6.png");

