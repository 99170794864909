// @ts-nocheck
import React, { useState } from "react";
import AddCard from "../component/myProduct/AddCard";
import HeaderProduct from "../component/myProduct/HeaderProduct";
import ProductFilter from "../component/myProduct/ProductFilter";
import ProductList from "../component/myProduct/ProductList";
import ProductPopupComponent from "../component/myProduct/ProductPopupComponent";
import { useNavigate } from "react-router-dom";

const MyProducts = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [tick, setTick] = useState(false);
  const [btnChange, setBtnChange] = useState(1);

  const showCartHandler = () => {
    setShowCart(true);
  };
  const showCartCloseHandler = () => {
    setShowCart(false);
    setTick(true);
  };

  const showPopupHander = () => {
    setShowPopup(!showPopup);
    // console.log("099");
  };

  const addProductHandler = (e) => {
    // console.log("products:", e);
  };

  return (
    <div className="my-product mx-auto w-98 w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
      <HeaderProduct
        title="Mockups "
        btnText="Design Products"
        onClick={() => navigate("/lets-start")}
        setBtnChange={setBtnChange}
        btnChange={btnChange}
      />
      <ProductFilter
        tick={tick}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
        showCartHandler={showCartHandler}
        setBtnChange={setBtnChange}
        btnChange={btnChange}
        page={"My products"}
      />
      {/* <ProductList
        tick={tick}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
        showCartHandler={showCartHandler}
        setBtnChange={setBtnChange}
        btnChange={btnChange}
      /> */}
      <AddCard
        showCart={showCart}
        showCartCloseHandler={showCartCloseHandler}
      />
      {showPopup && <ProductPopupComponent showPopupHander={showPopupHander} />}
    </div>
  );
};

export default MyProducts;
