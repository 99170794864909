import React from "react";
import DashboardDetail from "../component/Dashboard/DashboardDetail";
import DashboardOrder from "../component/Dashboard/DashboardOrder";
import DashboardShipment from "../component/Dashboard/DashboardShipment";
import { useNavigate } from "react-router-dom";

const DashboardScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="dashboard-screen mx-auto w-97 w-lg-90 w-xl-85 w-xxl-80">
      <div className=" d-flex flex-column flex-sm-row ac-jb mx-0 mt-3 my-sm-3 my-md-4 flex-row">
        <div className="">
          <h4 className="sect-title mb-2">Dashboard</h4>
          <p className="mb-3 mb-md-0 primary f4 fs-15 fs-md-19 fs-lg-20 fs-xl-22">
            Get all the merch details you need, right here 
          </p>
          {/* <button
            onClick={() => navigate("/lets-start")}
            className=" get-start-btn border-0 my-2 mx-0">
            Place New Order
          </button> */}
        </div>
        <div className="d-flex">
          {/* <button
            onClick={() => navigate("/select-product")}
            className="f4 get-start-btn bg-lt-blue primary my-0 mx-2 border-primar">
            Send
          </button> */}
          <button
            onClick={() => navigate("/lets-start")}
            className=" get-start-btn border-0 my-0 mb-3 mb-sm-0 mx-2">
            Place new order
          </button>
        </div>
      </div>
      <DashboardDetail />
      {/* <div className="d-flex flex-column flex-sm-row">
        <DashboardOrder />
        <DashboardShipment />
      </div> */}
    </div>
  );
};

export default DashboardScreen;
