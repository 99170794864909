// @ts-nocheck
import React from "react";
import { closeIcon, logist, product1 } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import YourStorageComp from "./YourStorageComp";
import { useState } from "react";

const ProductList = ({ showPopupHander, tick, list, type }) => {
  // console.log(tick);
  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);

  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(!showUpdate);
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="login-product d-flex flex-wrap">
      {list?.map((item, ind) => {
        return (
          <div
            className="py-2 px-1 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-box-tl"
            key={ind}
          >
            <div className="prod-list-box">
              <div className="">
                <p
                  className={`lt-ready fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3`}
                  style={
                    type == 2
                      ? { background: "#40bb53", color: "white" }
                      : item?.status == 6
                      ? { background: "#40bb53", color: "white" }
                      : // : { background: "#f0bc10", color: "white" }
                        { background: "#40bb53", color: "white" }
                  }
                >
                  {type == 2
                    ? "Completed"
                    : // : item?.status == 5
                    // ? "Design In Process"
                    item?.status == 6
                    ? "Ready to Buy"
                    : item?.status == 8
                    ? "Completed"
                    : "Ready to Buy"}
                </p>
                <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                  Project ID {item?.project_code}
                </h3>
                <div className="d-flex align-items-center">
                  <img className="logist" src={logist} alt="" />
                  <p className="parag mb-0 ms-2 fs-xl-16">
                    {" "}
                    {`${item?.no_of_packs}Qty`}
                  </p>
                </div>
              </div>
              <button className="cust-btn prod-list-box-img">
                <img
                  src={item?.display_img ? item?.display_img : product1}
                  alt=""
                  style={{ height: "200px", objectFit: "contain" }}
                />
              </button>
              <div className="d-flex justify-content-between ">
                <p className="parag mb-0 ms-2 fs-xl-16"> Order Requested</p>
                <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                  {" "}
                  {item?.start_date}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                {/* <p className="parag mb-0 ms-2 fs-xl-16">Price</p>
                <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                  {item?.status === 5 ? "Processing" : ""}
                </p> */}
                {/* {item?.status === 5 ? (
                  <>
                    <p className="parag mb-0 ms-2 fs-xl-16">Price</p>
                    <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                      Processing
                    </p>
                  </>
                ) : (
                  <>
                    <p className="parag mb-0 ms-2 fs-xl-16">Price</p>
                    <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                      {commaValue(item?.total)}
                    </p>
                  </>
                )} */}
                <>
                  <p className="parag mb-0 ms-2 fs-xl-16">Price</p>
                  <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                    {commaValue(item?.total)}
                  </p>
                </>
              </div>
              <div className="d-flex justify-content-center ">
                <button
                  // onClick={showPopupHander}
                  onClick={() => {
                    // if (item?.status == 6) {
                    // navigate("/my-shipment", { state: item });
                    // }
                    // if (item?.status > 5 || type == 2) {
                    navigate("/my-shipment", { state: item });
                    // }
                  }}
                  className={`get-start-btn border-0 ${
                    type == 2 ? "" : item?.status == 5 ? "" : ""
                  }`}
                >
                  View Request
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
