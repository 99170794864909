import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../../redux/api/constants";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon } from "../../../assets/img";
import { useNavigate } from "react-router-dom";
import {
  useAddToCartMutation,
  usePresetPackViewMutation,
} from "../../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import Carousel from 'react-bootstrap/Carousel';
// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponent = ({ showPopupHander, packlist, itemId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("user_details"));
  const Token = localStorage.getItem("token");
  const [dataBase, setDataBase] = useState(null);

  const [cartBtn, setCartBtn] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // RTK QUERY
  const [addToCartApi] = useAddToCartMutation();

  // ADD CART
  const getAddToCart = () => {
    if (!Token) {
      addLocalCart();
    } else {
      let temp = [];

      packlist?.preset_product?.map((item, ind) => {
        temp.push({
          product_id: item?.product?.id,
          product_sku_id: item?.product_sku?.id ? item?.product_sku?.id : "",
          no_of_colors: 1,
          qty: 1,
          price: +packlist?.total_price,
        });
      });

      const formData = new FormData();
      formData.append("user_id", userDetails?.id);
      formData.append("type", 2);
      formData.append("no_of_packs", 25);
      formData.append("preset_pack_id", packlist?.id);
      if (temp?.length > 0) {
        temp?.map((item, ind) => {
          formData.append(`cart_details[${ind}][qty]`, item?.qty);
          formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
          formData.append(`cart_details[${ind}][price]`, item?.price);
          formData.append(
            `cart_details[${ind}][no_of_colors]`,
            item?.no_of_colors
          );

          if (item?.product_sku_id) {
            formData.append(
              `cart_details[${ind}][product_sku_id]`,
              item?.product_sku_id
            );
          }
        });
      }

      dispatch(saveLoader(true));
      setCartBtn(true);

      addToCartApi(formData)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setCartBtn(false);

          navigate("/products/preset-pack/preset-pack-list");
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setCartBtn(false);
          console.log(err);
        });
    }
  };

  // Db Intialize starts
  const createDB = () => {
    // var open = window.indexedDB.open("CartDB");
    var open = indexedDB.open("CartDB");

    open.onupgradeneeded = function () {
      let db = open.result;
      if (!db.objectStoreNames.contains("CartTable")) {
        db.createObjectStore("CartTable");
      }
    };

    open.onsuccess = function () {
      let db = open.result;
      setDataBase(db);
      console.log("success!");
    };

    open.onerror = function () {
      console.log(open.error);
    };
  };

  // ADD LOCAL CART
  const addLocalCart = () => {
    let tx = dataBase.transaction("CartTable", "readwrite");
    let store = tx.objectStore("CartTable");
    let pro_temp = [];
    let preset_pack = {
      bundle_id: packlist?.bundle_id,
      description: packlist?.description,
      discount: packlist?.discount,
      id: packlist?.id,
      notes: packlist?.notes,
      price: packlist?.price,
      title: packlist?.title,
      total_price: packlist?.total_price,
    };

    let packs = {
      25: 0,
      50: 0,
      100: 0,
      150: 0,
      250: 0,
      500: 0,
      1000: 0,
    };

    packlist?.preset_product?.map((item, ind) => {
      packs[25] = packs[25] += item?.product?.for_25;
      packs[50] = packs[50] += item?.product?.for_50;
      packs[100] = packs[100] += item?.product?.for_100;
      packs[150] = packs[150] += item?.product?.for_150;
      packs[250] = packs[250] += item?.product?.for_250;
      packs[500] = packs[500] += item?.product?.for_500;
      packs[1000] = packs[1000] += item?.product?.for_1000;
    });

    packlist?.preset_product?.map((item, ind) => {
      pro_temp.push({
        no_of_colors: 1,
        price: item?.product?.for_25,
        product: item?.product,
        product_id: item?.product?.id,
        product_sku: item?.product_sku,
        product_sku_id: item?.product_sku?.id ? item?.product_sku?.id : null,
        default_sku: item?.product_sku,
        qty: 1,
        total_price: packlist?.total_price,
      });
    });

    let final = {
      no_of_packs: 25,
      price_per_pack: Number(+packlist?.total_price).toFixed(2),
      total_amount: Number(+packlist?.total_price * 25).toFixed(2),
      cartDetail: pro_temp,
      preset_pack: preset_pack,
      pack_prices: packs,
    };

    // console.log("final", final);
    dispatch(saveLoader(true));
    setCartBtn(true);
    let request = store.put(final, "preset_pack");

    request.onsuccess = function () {
      console.log("success!");
      dispatch(saveLoader(false));
      setCartBtn(false);

      navigate("/products/preset-pack/preset-pack-list");
    };

    request.onerror = function () {
      console.log(request.error);
      dispatch(saveLoader(false));
      setCartBtn(false);
    };
  };

  useEffect(() => {
    if (!Token) {
      createDB();
    }
  }, []);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="modal-popup preprod-pack">
      <div onClick={showPopupHander} className="back-close" />

      <div className="center-content d-flex flex-column flex-lg-row ">
        <button
          onClick={showPopupHander}
          className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="left-popup col-md-6 mt-3">
          <div className="imges m-3 bg-lt-blue">
            {/* <OwlCarousel {...productDetailImg}>
              {packlist?.images?.map((img) => {
                return (
                  <img
                    src={img}
                    className="carousal-img"
                    style={{ width: "100%", objectFit: "cover", margin: "20px" }}
                  />
                );
              })}
            </OwlCarousel> */}

            <Carousel activeIndex={index} onSelect={handleSelect}>
              {packlist?.images?.map((img) => {
                return (
                  <Carousel.Item>
                    <img
                      src={img}
                      className="carousal-img"
                      style={{ width: "80%", objectFit: "contain", margin: "20px" }}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className="rit-pop text-start p-2 ps-4 ">
          <h2 className="pe-lg-5  mt-5">{packlist?.title}</h2>
          <p className="parag primary1 pe-4">{packlist?.description}</p>

          <div className="d-flex flex-wrap ac-js gap-2">
            <h2 className="op-80 mt-2 mt-lg-4">{`₹${packlist?.total_price
              ? numberWithCommas(packlist?.total_price)
              : " "
              }/item`}</h2>
            <h6
              className="op-80 mt-2 mt-lg-4 text-muted font-weight-bold"
              style={{ textDecoration: "line-through" }}
            >{`₹${packlist?.price ? packlist?.price : " "}`}</h6>
            <h6 className="op-80 mt-2 mt-lg-4 text-success font-weight-bold">{`₹${packlist?.discount ? packlist?.discount + "% off" : " "
              }`}</h6>
          </div>
          <p className="parag1 primary1 pe-4">
            * Price based on 100 units and 1 color design
          </p>
          <h3 className="mt-3 mt-lg-4">Kit Includes:</h3>
          {packlist?.points?.map((point) => {
            return (
              <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">{point}</p>
              </div>
            );
          })}
          {/* <p className="parag1 primary1 pe-4">{item?.points}</p> */}
          {/* <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">
                  No Mess Eco Spiral Fill[Recommended]
                </p>
              </div>
              <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">Gemini Cork Bottle</p>
              </div> */}
          {/* <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">Roots Comfy Hoodie</p>
              </div>
              <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">
                  Money Marker Spiral Notebook 4.6" x 7"
                </p>
              </div>
              <div className="pop-modal-p mx-2">
                <p className="parag1 primary1 pe-4">
                  Standard Custom Mailer Box
                </p>
              </div> */}

          <button
            onClick={() => getAddToCart()}
            disabled={cartBtn}
            className={`get-start-btn border-0 mb-2 w-auto px-3 px-md-4   ${cartBtn && "opacity-50"
              }`}
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPopupComponent;
