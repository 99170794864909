import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useGetProductPreMutation } from "../../redux/api/api";

const ProductHeader = ({ title, list, type, pendingitem }) => {
  const [mockupGet] = useGetProductPreMutation();

  const mockUp = () => {
    const formdata = new FormData();
    formdata.append("project_detail_id", pendingitem?.id);
    mockupGet(formdata)
      .unwrap()
      .then((res) => {
        console.log(res);
        const link = document.createElement("a");

        link.href = res?.url;
        link.setAttribute("download", "");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const mockUp = async () => {
  //   const formdata = new FormData();
  //   formdata.append("project_detail_id", pendingitem?.id);
  //   try {
  //     const res = await mockupGet(formdata).unwrap();
  //     console.log(res, "download");

  //     if (res?.url) {
  //       const response = await fetch(res.url);

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok.");
  //       }

  //       const blob = await response.blob();

  //       const link = document.createElement("a");
  //       const url = URL.createObjectURL(blob);
  //       link.href = url;

  //       let fileName = "downloaded-file";
  //       const contentType = response.headers.get("Content-Type");
  //       if (contentType.includes("application/pdf")) {
  //         fileName += ".pdf";
  //       }
  //       // } else if (contentType.includes("image")) {
  //       //   fileName += ".jpg";
  //       // } else if (
  //       //   contentType.includes(
  //       //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //       //   )
  //       // ) {
  //       //   fileName += ".xlsx";
  //       // } else if (contentType.includes("application/msword")) {
  //       //   fileName += ".doc";
  //       // } else {
  //       //   fileName += ".bin";
  //       // }

  //       link.setAttribute("download", fileName);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     }
  //   } catch (err) {
  //     console.error("Error downloading the file:", err);
  //   }
  // };
  // console.log("title", title);
  return (
    <div className="my-prod-det d-flex  flex-column flex-md-row  as-jb">
      <h4 className="sect-title mb-1 mb-md-0 w-100 w-lg-50">
        {title ? title : ""}
      </h4>
    </div>
  );
};

export default ProductHeader;
