// @ts-nocheck
import React, { useState } from "react";
import AddCard from "../component/myProduct/AddCard";
import HeaderProduct from "../component/myProduct/HeaderProduct";
import ProductFilter from "../component/myProduct/ProductFilter";
import ProductList from "../component/myProduct/ProductList";
import ProductPopupComponent from "../component/myProduct/ProductPopupComponent";
import { useNavigate } from "react-router-dom";

const ProductsLibrary = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [tick, setTick] = useState(false);
  const [btnChange, setBtnChange] = useState(1);

  const showCartHandler = () => {
    setShowCart(true);
  };
  const showCartCloseHandler = () => {
    setShowCart(false);
    setTick(true);
  };

  const showPopupHander = () => {
    setShowPopup(!showPopup);
    console.log("099");
  };

  const addProductHandler = (e) => {
    console.log("products:", e);
  };

  return (
    <div className="my-product mx-auto w-95 w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
   
      <div className="top-product d-flex flex-column flex-sm-row ac-jb mt-3 mb-2">
        <div className="w-70 mb-0">
          <h6 className="sect-title px-2 px-lg-3">Product Library</h6>
          </div>
      </div>
      <ProductFilter
        tick={tick}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
        showCartHandler={showCartHandler}
        setBtnChange={setBtnChange}
        btnChange={btnChange}
        page={"Products Library"}
      />
      
      <AddCard
        showCart={showCart}
        showCartCloseHandler={showCartCloseHandler}
      />
      {showPopup && <ProductPopupComponent showPopupHander={showPopupHander} />}
    </div>
  );
};

export default ProductsLibrary;
