import React from "react";
import { dashboard, listIcon, servPlat1 } from "../../assets/img";

const ImprossiveFeature = () => {
  return (
    <div className="my-2 my-lg-5 improsive-feature w-70" style={{margin : "80px auto"}}>
      <div className="left-improsive-feature my-md-5 me-md-5">
        <h4>The HQ Exclusive</h4>
        <div className="">
          <div className="">
            <p>Psst! You don’t want to scroll past this. Boring isn’t in our playbook.</p>
            <p>Here is our monthly product spotlight! Unlock this exceptional deal available only for this month. Act quickly and don’t miss out on this exclusive offer!
            </p>
          </div>
        </div>
        <a className="get-start-btn mb-3" href="#/">
          Order now
        </a>
      </div>
      <div className="rit-improsive-feature my-md-5 bg-lt-blue me-md-0 ms-md-5">
        <img src={servPlat1} />
      </div>
    </div>
  );
};

export default ImprossiveFeature;