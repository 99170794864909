import React, { useEffect, useState } from "react";
import BlogEnquiry from "../component/Company/Blog/BlogEnquiry";
import BlogTab from "../component/Company/Blog/BlogTab";
import Footer from "../navbar/Footer";
import BlogBanner from "../component/Company/Blog/BlogBanner";
import { useBlogListMutation } from "../redux/api/api";
import { useLocation } from "react-router-dom";

const BlogScreen = () => {
//  const [list,setList]=useState([])

//   //RTK
//   const [blogListApi]=useBlogListMutation();



  //BlogList 
//   const getBlogList=()=>{
//     const formData=new URLSearchParams()
//     blogListApi(formData)
//     .unwrap()
//     .then(res=>{
//       console.log(res?.lists)
//       setList(res?.lists)
//     })
//     .catch(err=>{
//       console.log(err)
//     })
//   }
//  useEffect(()=>{
//    getBlogList()
//  },[])



  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className="tl-blog">
    <BlogBanner/>
      <BlogTab type="blog"  />
      <BlogEnquiry />
      <Footer />
    </div>
  );
};

export default BlogScreen;
