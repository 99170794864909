import React, { useRef } from "react";
import AboutHQ from "../component/Company/AboutUs/AboutHQ";
import MissionVission from "../component/Company/AboutUs/MissionVission";
import GuideValue from "../component/Company/AboutUs/GuideValue";
import MeetCircle from "../component/Company/AboutUs/MeetCircle";
import ExpertTeam from "../component/Company/AboutUs/ExpertTeam";
import JoinTeam from "../component/Company/AboutUs/JoinTeam";
import Footer from "../navbar/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AboutUsScreen = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  return (
    <div className="company-tl">
      <AboutHQ handleClick={handleClick} />
      <MissionVission />
      <GuideValue />
      <MeetCircle />
      <ExpertTeam />
      <div ref={ref}>
        <JoinTeam />
      </div>

      <Footer />
    </div>
  );
};

export default AboutUsScreen;
