// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Message,
  Messager,
  Rectangle,
  Vector,
  bg1,
  closeIcon,
  downArrow,
  logo,
  searchIcon,
} from "../assets/img";
import ProductList from "../component/Order/ProductList";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useOrderListMutation } from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const OrderScreen = () => {
  const dispatch = useDispatch();
  const [meassage, setMessage] = useState(false);
  const [list, setList] = useState([]);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [pagenation, setPagenation] = useState({});
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  const HideMessage = () => {
    setMessage(!meassage);
  };

  const [orderlist] = useOrderListMutation();
  const createOrder = (searchs, status) => {
    setSearch(searchs);
    setStatus(status);
    dispatch(saveLoader(true));
    const formdata = new FormData();
    if (searchs) {
      formdata.append("search", searchs);
    }
    formdata.append("type", type);
    if (status) {
      formdata.append("status", status);
    }
    orderlist(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];

        res?.lists?.map((item, ind) => {
          let obj = {
            ...item,
          };

          if (item?.projectDetail?.[0]?.id) {
            let projectDetail = item?.projectDetail?.[0];

            if (projectDetail?.product_sku?.id) {
              let finder = projectDetail?.product?.colorImages?.find(
                (i) => i?.color_id == projectDetail?.product_sku?.color_id
              );
              if (finder?.id) {
                obj.display_img = finder?.image_url;
              } else {
                obj.display_img = projectDetail?.product?.defaultImage;
              }
            } else {
              obj.display_img = projectDetail?.product?.defaultImage;
            }
          }

          temp.push(obj);
        });
        setList(temp);
        setPagenation(res?.pagination_meta);
        dispatch(saveLoader(false));
        // navigate("/my-order");
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };
  useEffect(() => {
    createOrder();
  }, [type]);
  return (
    <div className="order-screen">
      <div className="px-1 wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2 m-auto">
        <div className="d-flex ac-jb w-100">
          <h2 className="sect-title mb-0 me-2 ">Orders</h2>
          <button
            onClick={() => navigate("/lets-start")}
            className="get-start-btn border-0"
          >
            Start new order
          </button>
        </div>
        <div className="d-flex">
          <div className="d-flex pe-3 flex-column align-items-center position-relative">
            <button
              className={`cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20${
                type == 1 ? "active" : ""
              }`}
              onClick={() => setType("1")}
            >
              Current
            </button>
            {type == 1 ? <div className="active"></div> : ""}
          </div>
          <div className="d-flex px-3 flex-column align-items-center position-relative">
            <button
              className={`cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20${
                type == 2 ? "active" : ""
              }`}
              onClick={() => setType("2")}
            >
              Completed
            </button>
            {type == 2 ? <div className="active"></div> : ""}
          </div>
        </div>
        {/* <div className="my-product w-100 w-md-95 w-xl-85 w-xxl-80 mb-5">
          {type == 1 && (
            <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4  align-items-start">
              <div className="d-flex ac-js mb-3 mb-md-0">
                <div className="position-relative">
                  <FilterAltIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark" />
                </div>
                <div className="position-relative d-flex ac-jc">
                  <p
                    className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}
                  >
                    Status
                  </p>
                  <div className="dropdownbox m-3">
                    <select
                      className="rm-select-arrow cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 
                  fs-xxl-18 primary mb-0 text-center"
                      onChange={(e) => createOrder("status", e.target.value)}
                      value={status}
                    >
                      <option value={1}>In Progress</option>
                      <option value={2}>Ready to Buy</option>
                    </select>
                    <img
                      className="arrow-icon"
                      style={{ left: "100%" }}
                      alt="icon"
                      src={downArrow}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex ac-js mb-0  flex-column flex-sm-row">
                <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
                  <img src={searchIcon} alt="" />
                  <input
                    type="text"
                    onChange={(e) => createOrder(e.target.value)}
                    value={search}
                    className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
                    placeholder="Search Products"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
          )}
        </div> */}
        <ProductList list={list} type={type} />
        {/* <div className="position-relative">
          <button className="get-start-btn border-0" onClick={HideMessage}>
            <img src={Message} alt="" className="messageIcon " />
            Chat
          </button>
          {meassage && (
            <div className="messagePopup position-absolute topvalue">
              <div className="d-flex ac-jb mt-2">
                <img src={logo} className="messagelogo" alt="" />
                <div className=" d-flex gap-2">
                  <div className="show d-flex ac-jc">
                    <p className="icons">...</p>
                  </div>
                  {meassage && (
                    <div className="show d-flex ac-jc">
                      <p className="icons mt-2" onClick={HideMessage}>
                        -
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className="cust-btn mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20 mt-3">
                  Chat With MerchHQ
                </p>
              </div>
              <div>
                <p className="parag text-dark mb-0 mt-0 ms-2 fs-xl-16 mt-2">
                  Howdy, How can we help you??
                </p>
              </div>
              <div className="d-flex ac-jc py-3">
                <button className="get-start-btn border-0 mt-4">
                  Start Chat
                </button>{" "}
              </div>
              <div className="d-flex ac-jc">
                <img src={Messager} alt="" className="messager" />
                <p className="parag text-dark m-0 fs-xl-16 ">
                  Powered by Messenger
                </p>
              </div>
            </div>
          )}
        </div> */}
        {list?.length > 0 && (
          <div className="d-flex ac-jc mb-5 mt-4">
            <button className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2 qunt-btn1">
              <ArrowBackIosIcon className="f4 fs-15 fs-md-15" />
            </button>
            <p className="parag mb-0 mx-4">{`1-12 of ${
              pagenation?.last_page ? pagenation?.last_page : ""
            } requested`}</p>
            <button className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2 qunt-btn1">
              <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderScreen;
