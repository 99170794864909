// @ts-nocheck
import React from "react";
import {
  missionIcon,
  robotIcon,
  telescopeIcon,
  vissionIcon,
} from "../../../assets/img";

const MissionVission = () => {
  return (
    <div className="abt-hq mt-4 w-90 w-md-70 mx-auto mb-2 mb-md-3 ac-jb d-flex flex-column flex-sm-row">
      <div className="box-mis-vis wi-100 w-sm-48 w-xl-48 mt-2 mt-sm-2 p-3 py-lg-5   ">
        <img src={robotIcon} alt="" />
        <div>
          <h5 className="f4 mb-2 mb-lg-3  fs-14 fs-md-16 fs-lg-18 fs-xl-19">
            Our Mission
          </h5>
          <p className="f2 primary1 mb-2 mb-lg-3  fs-14 fs-lg-15 fs-xl-16 w-70">
            Making merchandise accessible to all, through a seamless and
            innovative buying experience.
          </p>
        </div>
      </div>
      <div className="box-mis-vis wi-100 w-sm-48 w-xl-48  mt-4 mt-sm-2 p-3 py-lg-5 d-flex ac-jc">
        <img src={telescopeIcon} alt="" />
        <div>
          <h5 className="f4 mb-2 mb-lg-3  fs-14 fs-md-16 fs-lg-18 fs-xl-19">
            Our Vision
          </h5>
          <p className="f2 primary1 mb-2 mb-lg-3  fs-14 fs-lg-15 fs-xl-16 w-70">
            Creating connections, belonging and expression through merchandise
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionVission;
