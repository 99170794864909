import React, { useEffect } from "react";
import ServicesHeadComp from "../component/ServicesComp/ServicesHeadComp";
import ServicesComp from "../component/ServicesComp/ServicesComp";
import ServicesComp2 from "../component/ServicesComp/ServicesComp2";
import ServicesComp3 from "../component/ServicesComp/ServicesComp3";
import ServicesComp4 from "../component/ServicesComp/ServicesComp4";
import ServicesComp5 from "../component/ServicesComp/ServicesComp5";
import ServicesComp6 from "../component/ServicesComp/ServicesComp6";
import LetStartComp from "../component/ServicesComp/LetStartComp";
import ServingFor from "../component/Home/ServingFor";
import ContactUs from "../component/ServicesComp/ContactUs";
import Footer from "../navbar/Footer";
import { platformService } from "../redux/api/DummyJson";
import { useLocation } from "react-router-dom";


const ServicesScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="mb-3 w-100">
      <div
        className="w-100 h-100 d-flex ac-jc py-4"
        // style={{ background: "#dde5fc" }}
      >
        <ServicesHeadComp />
      </div>
      <div className="w-100 mt-2">
        <ServingFor />
      </div>
      <div className="w-100 h-100 ac-jc ">
          {platformService?.map((it, ind) => (
            <ServicesComp
              key={ind} // Always use a unique key for mapped components
              img={it?.img}
              title={it?.title}
              desc={it?.desc}
              isOdd={ind % 2 !== 0} // Check if the index is odd
            />
          ))}
        </div>


      <div className="w-100 h-100 d-flex ac-jc py-5 ">
        <LetStartComp />
      </div>
      {/* <div className="mt-5 w-100 py-5 d-flex ac-jc">
        <ContactUs />
      </div> */}
      <Footer />
    </div>
  );
};

export default ServicesScreen;
