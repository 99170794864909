// @ts-nocheck
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { logist, product1, ship1, ship2 } from "../assets/img";
import RequiredSummary from "../component/Order/RequiredSummary";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateQuantity from "../component/MyProductDetail/UpdateQuantity";
import YourStorageComp from "../component/Order/YourStorageComp";
import ShipmentPopup from "../component/Shipments/ShipmentPopup";
import { useGetOrderDataMutation } from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const ShippmentScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [storagePopup, setStoragePopup] = useState(false);
  const [shipPopup, setShipPopup] = useState(false);
  const [list, setList] = useState({});
  const [breakdownList, setBreakdownList] = useState({});
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [toggleBtn2, setToggleBtn2] = useState(2);

  const [orderData] = useGetOrderDataMutation();

  const createOrder = () => {
    dispatch(saveLoader(true));
    const formdata = new FormData();

    formdata.append("project_id", location.state?.id);

    orderData(formdata)
      .unwrap()
      .then((res) => {
        // console.log(res, "  ");
        let temp = { ...res?.data };

        let discount_amount_ = res?.data?.products?.reduce(
          (n, { discount_amount }) => n + discount_amount,
          0
        );

        if (res?.data?.discount_type == 1) {
          temp.discount_amount = discount_amount_;
        }

        setList(temp);
        // const extra=res?.data?.products.map(item=>item.extra_charges)
        const extra = res?.data?.products;

        extra_charges_cus(extra, res?.data?.discount_type);
        // setProjectDetail(extra)
        // console.log(extra,"res?.data?.extra_charges")
        dispatch(saveLoader(false));
        // navigate("/my-order");
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };
  useEffect(() => {
    if (location.state?.id) {
      createOrder();
    }
  }, []);
  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(false);
    // setStoragePopup(!storagePopup);
    setStoragePopup(false);
    setShipPopup(false);
  };

  const viewBreakdown = (item) => {
    setBreakdownList(item);
    setShowUpdate(true);
  };

  const extra_charges_cus = (project_detail, tax_type) => {
    if (project_detail?.length > 0) {
      let extra_charges_arr = [];
      project_detail.map((item, ind) => {
        let temp = [];

        let include_price = item?.extra_charges?.filter(
          (i) => +i?.client_price == 1
        );
        let display_to_client = item?.extra_charges?.filter(
          (i) => +i?.client_price == 2
        );

        let extra_obj = {};

        if (include_price?.length > 0) {
          include_price?.forEach((price) => {
            if (extra_obj[price.client_price]) {
              extra_obj[price.client_price].final_amount += +price.final_amount;
              extra_obj[price.client_price].net_price += +price.net_price;
              extra_obj[price.client_price].margin += +price.margin;
            } else {
              extra_obj[price.client_price] = {
                name: price?.name,
                client_price: price?.client_price,
                margin: +price?.margin,
                net_price: +price?.net_price,
                final_amount: +price?.final_amount,
              };
            }
          });
        }

        let discount_amount = tax_type == 1 ? +item?.discount_amount : 0;

        // FOR INCLUDE PRICE
        if (Object.values(extra_obj)?.length > 0) {
          Object.values(extra_obj).map((price, Pind) => {
            let discount_percent = 0;

            if (item?.discount_percent) {
              discount_percent = (+item?.price * item?.discount_percent) / 100;
            }

            // let discount_value = +item?.price - discount_percent;
            let discount_value = +item?.price;

            // let net_amount = +discount_value - discount_amount;
            let net_amount = +discount_value;

            // let final = +net_amount + +price?.final_amount;
            let final = +net_amount;
            let net_price = +item?.price / +item?.qty;
            let net_price_;

            let tax = item?.tax_percent;
            let tax_value = (+final * tax) / 100;
            let tax_amount = +final + +tax_value;
            let obj = { ...price };
            obj.margin = +price?.margin;
            // obj.net_price = +net_price + +price?.net_price;
            // obj.final_amount = +discount_value + +price?.final_amount;
            obj.final_amount = +discount_value;
            obj.tax = tax;
            obj.tax_value = +Number(tax_value).toFixed(2);
            obj.tax_amount = +Number(tax_amount).toFixed(2);
            obj.discount_amount = +Number(discount_amount).toFixed(2);

            if (item?.after_discount > 0) {
              net_price_ = item?.after_discount;
              // obj.net_price = net_price_ + price?.net_price;
              obj.net_price = net_price_;
              obj.price_per_pack = item?.price_per_pack;
            } else {
              net_price_ = item?.price_per_pack;
              // obj.price_per_pack = +item?.price_per_pack + +price?.net_price;
              obj.price_per_pack = +item?.price_per_pack;
              obj.net_price = "";
            }

            if (item?.after_discount) {
              let discountPercentage =
                ((+item?.price_per_pack - +item?.after_discount) /
                  +item?.price_per_pack) *
                100;
              let discountPercentage_ = Number(discountPercentage).toFixed(2);
              obj.dis_percen = +discountPercentage_;
            }

            temp.push(obj);
          });
        } else if (
          (item?.extra_charges?.length > 0 || item?.extra_charges == null) &&
          Object.values(extra_obj).length == 0
        ) {
          let discount_percent = 0;

          if (item?.discount_percent) {
            discount_percent = (+item?.price * item?.discount_percent) / 100;
          }

          let discount_value = +item?.price - discount_percent;
          // let net_amount = +item?.price - discount_amount;
          let net_amount = +item?.price;
          // let net_amount = +discount_value;

          let final = +net_amount;
          let tax = item?.tax_percent;
          let tax_value = (+final * tax) / 100;
          let tax_amount = +final + +tax_value;
          let obj = {};
          obj.margin = item?.margin_percent ? +item?.margin_percent : 0;
          // obj.net_price = +item?.price / +item?.qty;
          obj.final_amount = +item?.price;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);
          obj.discount_amount = +Number(discount_amount).toFixed(2);

          if (item?.after_discount > 0) {
            obj.price_per_pack = +item?.price_per_pack;
            obj.net_price = +item?.after_discount;
          } else {
            obj.price_per_pack = item?.price_per_pack;
            obj.net_price = "";
          }

          if (item?.after_discount) {
            let discountPercentage =
              ((+item?.price_per_pack - +item?.after_discount) /
                +item?.price_per_pack) *
              100;
            let discountPercentage_ = Number(discountPercentage).toFixed(2);
            obj.dis_percen = +discountPercentage_;
          }

          temp.push(obj);
        }

        // FOR DISPLAY TO CLIENT
        display_to_client?.forEach((price) => {
          let tax = item?.tax_percent;
          let tax_value = (+price?.final_amount * tax) / 100;
          let tax_amount = +price?.final_amount + +tax_value;
          let obj = { ...price };
          obj.margin = +price?.margin;
          obj.final_amount = +price?.final_amount;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);

          if (item?.after_discount > 0) {
            obj.net_price = +price?.net_price;
          } else {
            obj.price_per_pack = +price?.net_price;
            obj.net_price = "";
          }

          temp.push(obj);
        });

        // console.log("temp", temp);

        extra_charges_arr.push({
          ...item,
          extra_charges: temp,
        });
      });

      // console.log("extra_charges_arr", extra_charges_arr);

      const totalValue = extra_charges_arr.reduce((acc, item) => {
        return acc + parseFloat(item.tax_value || 0);
      }, 0);

      setProjectDetail(extra_charges_arr);
    }
  };

  const summer = (amount1, amount2) => {
    let sum = Number(+amount1 + +amount2).toFixed(2);
    let toNum = +sum;
    let amount = toNum
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="tl-shipment-screen">
      {storagePopup && (
        <YourStorageComp
          showUpdate={storagePopup}
          showClickHandler={showClickHandler}
        />
      )}
      {shipPopup && (
        <ShipmentPopup
          showUpdate={shipPopup}
          showClickHandler={showClickHandler}
        />
      )}
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-90 px-2 px-lg-2">
        <button
          className="parag bg-transparent border-0"
          onClick={() => {
            navigate("/my-order");
          }}
        >
          <ArrowBackIosIcon /> Back to Orders
        </button>
        <h4 className="mb-0 mt-5 f4 fs-25 fs-sm-30 fs-lg-35 fs-xl-40">
          Products
        </h4>
        <div className="d-flex flex-wrap gap-4 align-items-start p-2 my-2 product-list-detail">
          {projectDetail?.map((item, ind) => {
            // FOR WITHOUT EXTRA CHARGE
            let dis_without_percen = "";
            let before_without_price = item?.project_detail_id?.retail_price;
            if (item?.project_detail_id?.after_discount > 0) {
              let discountPercentage =
                ((+item?.project_detail_id?.price_per_pack -
                  +item?.project_detail_id?.after_discount) /
                  +item?.project_detail_id?.price_per_pack) *
                100;
              dis_without_percen = Number(discountPercentage).toFixed(2) + "%";
            }
            return (
              <div
                className="d-flex w-100 box-shadow-product_new my-3"
                key={ind}
              >
                <div className="img-box-tee_new me-2">
                  <img
                    src={item?.image ? item?.image : product1}
                    className="w-90"
                  />
                </div>
                {/* <div className="w-70 px-3">
                  <p class="bg-approved lt-approved fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3">
                    Approved
                  </p>
                  <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                    {item?.name}
                  </h3>
                  <div className="d-flex align-items-center">
                    <img className="logists" src={logist} alt="" />
                    <p className="parag mb-0 ms-2 fs-xl-16">
                      {" "}
                      {`${item?.qty}qty`}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="parag mb-0 ms-2 fs-xl-16">
                      Subtotal: ₹{item?.tax_amount - item?.price}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="parag mb-0 ms-2 fs-xl-16">
                      Price: ₹{item?.price}{" "}
                    </p>
                  </div>

                  {list?.tax_type == 1 && (
                    <div className="d-flex align-items-center">
                      <p className="parag mb-0 ms-2 fs-xl-16">
                        Tax: {item?.tax_amount} tax
                      </p>
                    </div>
                  )}
                  <button
                    onClick={() =>
                      navigate("/my-product-View_Proof_detail", {
                        state: { project: list, type: "order" },
                      })
                    }
                    className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary mt-2"
                  >
                    View Proof
                    <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16" />
                  </button>
                </div> */}
                <div className="  product-img-table">
                  <p class="bg-approved lt-approved fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3">
                    Approved
                  </p>
                  <table className="table-design-new mt-2">
                    <thead>
                      <tr className="text-nowrap text-center">
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Name
                        </th>
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Qty
                        </th>
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Price Per Pack
                        </th>
                        {list?.discount_type == 1 && (
                          <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                            Discount
                          </th>
                        )}
                        {list?.discount_type == 1 && (
                          <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                            Discount Price Per Pack
                          </th>
                        )}
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Total Before Tax
                        </th>
                        {/* <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">HSN</th> */}
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Tax %
                        </th>
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Tax Value
                        </th>
                        <th className=" mb-0 ms-2 fs-xl-14 primary f4 text-nowrap">
                          Sub Total
                        </th>
                      </tr>
                    </thead>
                    {item?.extra_charges?.length > 0 ? (
                      <tbody>
                        {item.extra_charges.map((charge, eInd) => (
                          <tr className="text-center" key={eInd}>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {" "}
                              {+charge?.client_price == 2
                                ? charge?.name
                                : item?.name}
                            </td>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {item?.qty}
                            </td>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {charge?.price_per_pack
                                ? "₹" +
                                  commaValue(
                                    Number(charge?.price_per_pack).toFixed(2)
                                  )
                                : "-"}
                            </td>
                            {list?.discount_type == 1 && (
                              <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                                {charge?.dis_percen
                                  ? charge?.dis_percen + "%"
                                  : "-"}
                              </td>
                            )}
                            {list?.discount_type == 1 && (
                              <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                                {charge?.net_price
                                  ? "₹" +
                                    commaValue(
                                      Number(charge?.net_price).toFixed(2)
                                    )
                                  : "-"}
                              </td>
                            )}
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {charge?.final_amount
                                ? "₹" +
                                  commaValue(
                                    Number(charge?.final_amount).toFixed(2)
                                  )
                                : "-"}
                            </td>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {charge?.tax ? charge?.tax + "%" : "-"}
                            </td>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {charge?.tax_value
                                ? "₹" +
                                  commaValue(
                                    Number(charge?.tax_value).toFixed(2)
                                  )
                                : "-"}
                            </td>
                            <td className="parag mb-0 ms-2 fs-xl-13 text-nowrap">
                              {charge?.tax_amount
                                ? "₹" +
                                  commaValue(
                                    Number(charge?.tax_amount).toFixed(2)
                                  )
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : null}
                  </table>

                  <button
                    onClick={() => {
                      if (list?.presentation_ready == 1) {
                        navigate("/my-product-View_Proof_detail", {
                          state: { project: list, type: "order" },
                        });
                      } else {
                        let obj = {
                          ...list,
                          order_id: list?.id,
                          id: list?.project_request_id,
                        };
                        navigate("/my-product-detail", { state: obj });
                      }
                    }}
                    className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary mt-2"
                  >
                    {list?.presentation_ready == 1 ? "View Proof" : "View"}
                    <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16" />
                  </button>
                </div>
              </div>
            );
          })}
          {/* {projectDetail?.map((item, ind) => {
                      console.log(item,"total")

return( */}
          <div className="d-flex ac-je w-100">
            <p className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary  pe-2">
              Before tax{" "}
              <span className="black">
                {" "}
                ₹{list?.sub_total ? commaValue(list?.sub_total) : ""}
              </span>
            </p>
            <p className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary  pe-2">
              Tax value:{" "}
              <span className="black">
                {" "}
                ₹{list?.estimate_tax ? commaValue(list?.estimate_tax) : ""}
              </span>
            </p>

            <p className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary  pe-2">
              After tax:{" "}
              <span className="black">
                {" "}
                ₹
                {/* {list?.total_without_shiping_tax_amount
                  ? commaValue(list?.total_without_shiping_tax_amount)
                  : ""} */}
                {list?.sub_total && list?.estimate_tax
                  ? summer(+list?.sub_total, +list?.estimate_tax)
                  : list?.sub_total
                  ? commaValue(+list?.sub_total)
                  : ""}
              </span>
            </p>
          </div>
          {/* )})} */}
        </div>
        {/* <div className="product-shipment d-flex flex-column flex-lg-row justify-content-between mt-3">
          <div className="">
            <h4 className="mb-0 f4 fs-25 fs-sm-30 fs-lg-35 fs-xl-40">
              Products
            </h4>
            <div className="d-flex mt-3 mt-lg-4">
              <div className="prod-list-box-tl1">
                <div className="prod-list-boxss">
                  <img src={product1} alt="" />
                </div>
              </div>
              <div className=" px-3">
                <p class="bg-approved lt-approved fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3">
                  Approved
                </p>
                <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                  Product 1
                </h3>
                <div className="d-flex align-items-center">
                  <img className="logists" src={logist} alt="" />
                  <p className="parag mb-0 ms-2 fs-xl-16"> 25 Items</p>
                </div>
                <button
                  onClick={() => navigate("/my-product-detail")}
                  className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary mt-2"
                >
                  View Proof
                  <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16" />
                </button>
              </div>
            </div>
          </div>
          <div className="ship-stor">
            <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
              Shipping & Storage
            </h6>
            <div className="d-flex flex-column p-4 mt-3 mt-lg-3 inner-ship-stor position-relative">
              <div className="ship-recipt d-flex ac-jb mb-2 w-100">
                <h3 className="cust-btn mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark w-50">
                  Ship to Recipients
                </h3>
                <button
                  onClick={() => setShipPopup(!shipPopup)}
                  className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary w-50 d-flex justify-content-end align-items-center"
                >
                  View Shipments
                  <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16 ms-2" />
                </button>
              </div>
              <div className="ship-recipt d-flex mb-0 w-100">
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Shipment Qty
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    100
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Shipment Cost
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    $0.00
                  </h3>
                </div>
              </div>
              <button
                onClick={() => navigate("/select-product")}
                className="get-start-btn border-0 rounded mt-4 mb-3"
              >
                Create Shipment
              </button>
              <div className="ship-img">
                <img src={ship1} alt="" className={ship1} />
              </div>
            </div>
            <div className="d-flex flex-column p-4 inner-ship-stor position-relative">
              <div className="ship-recipt d-flex ac-jb mb-2 w-100">
                <h3 className="cust-btn mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark w-50">
                  Store in Warehouse
                </h3>
              </div>
              <div className="ship-recipt d-flex mb-0 w-100">
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Store Qty
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    100
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Storage Cost
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    $0.00
                  </h3>
                </div>
              </div>
              <button
                onClick={() => setStoragePopup(!storagePopup)}
                className="get-start-btn border-0 rounded mt-4 mb-3"
              >
                View Storage
              </button>
              <div className="ship-img">
                <img src={ship2} alt="" className={ship2} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <RequiredSummary
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
        showUpdate={setShowUpdate}
        openSummeryHandler={openSummeryHandler}
        list={list}
        viewBreakdown={viewBreakdown}
      />
      <UpdateQuantity
        showUpdate={showUpdate}
        showUpdateClick={setShowUpdate}
        showClickHandler={showClickHandler}
        breakdownList={breakdownList}
      />
    </div>
  );
};

export default ShippmentScreen;
