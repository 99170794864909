import React from "react";
import {
  dash1,
  dashh1,
  dashh2,
  dashh3,
  dashh4,
  kit1,
  logist,
} from "../../../assets/img";
import { BorderBottom } from "@mui/icons-material";
// import { type } from "@testing-library/user-event/dist/type";

const RedeemDetail = ({ redeem }) => {
  return (
    <>
      {/* <div className="improsive-feature flex-column flex-md-row">
        <div className="left-improsive-feature text-center text-md-start">
          <h4>{redeem[0]?.title}</h4>
          <div className="d-flex align-items-start">
            <p className="mb-0 mb-md-2">{redeem[0]?.desc}</p>
          </div>
          <button className="get-start-btn border-0 mx-auto mx-md-0" href="#/">
            View more
          </button>
        </div>
        <div className="rit-improsive-feature">
          <img src={redeem[0]?.img} />
        </div>
      </div>*/}
      {/* <div className="improsive-feature flex-column flex-md-row-reverse">
        <div className="left-improsive-feature text-center text-md-start">
          <h4>{redeem[1]?.title}</h4>
          <div className="d-flex align-items-start">
            <p className="mb-0 mb-md-2">{redeem[1]?.desc}</p>
          </div>
          <button className="get-start-btn border-0 mx-auto mx-md-0" href="#/">
            View more
          </button>
        </div>
        <div className="rit-improsive-feature">
          <img src={redeem[1]?.img} />
        </div>
      </div> */}

      <div className="m-auto">
        <div className="simple-pack w-90 w-md-75 mx-auto px-5">
          <h2 className="sect-title text-center dark-blue mt-3 mb-4">
            The responsible HQ.
          </h2>
          <p className="f2 fs-16 text-center" style={{ color: "#839dd1" }}>
            At the HQ we always ask ourselves about how things work out in the
            long run. The answer was simple but at the same time needed
            consistency, commitment, and a multi-dimensional approach. Above
            all, it had to be something we do from the heart and not the
            head. At the heart of our vision lies the ambition to establish
            ourselves as foremost thought-leaders in the realm of sustainable
            merchandise.
            <br />
            <br /> Guided by the three C's, we shape our strategies and choices,
            aiming to create a more sustainable merchandise experience for our
            people, our clients, and the planet.
            {/* At the heart of our vision lies the ambition to establish ourselves as
          foremost thought-leaders in the realm of sustainable merchandise.
          <br />
          <br />
          Guided by the three C's, we shape our strategies and choices, aiming
          to create a more sustainable merchandise experience for invenour people,
          our clients, and the planet. */}
          </p>
        </div>
        <div className="d-flex ac-jc flex-wrap w-70 w-90 mx-auto">
          <div className="d-block d-lg-none border-curv-img col-5 col-lg-3 mx-4 mt-4">
            <img src={dashh1} className="w-100" />
          </div>
          <div className="col-10 col-lg-6 me-lg-3">
            <h4 className="sect-title  dark-blue mt-5">Conscious</h4>
            <p className="f2 fs-16" style={{ color: "#839dd1" }}>
              To make mindful choices for the environment, <br />
              people and communities around us.
            </p>{" "}
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Conducting awareness programs about conscious consumerism.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Responsible sourcing practices.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Going digital!
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Sustainable packaging practices - 60%+ cardboard boxes
                  recycled.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Aiding agroforestry efforts through SayTrees.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                  Transition to solar power at our warehouse.
                </p>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block border-curv-img col-5 col-lg-3 mx-4 my-4">
            <img src={dashh1} className="w-100" />
          </div>
        </div>
        <div
          style={{
            background: "#fffaf1",
            marginTop: "30px",
            padding: "40px 0",
          }}
        >
          <div className="d-flex ac-jc flex-wrap w-70  w-90 m-auto">
            <div className="border-curv-img col-5 col-lg-3 mx-4 my-4">
              <img src={dashh3} className="w-100" />
            </div>

            <div className="col-10 col-lg-6 ms-md-3">
              <h5 className="sect-title  dark-blue">Community</h5>
              <p className="f2 fs-16" style={{ color: "#839dd1" }}>
                To foster a business culture that keeps community and its
                prosperity in mind.
                <br /> As they grow, we grow!
              </p>{" "}
              <div className="d-flex ">
                <div className="  me-2 primary1">
                  <p className="m-0 f5">. </p>{" "}
                </div>
                <div>
                  <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                    After school childcare program
                  </p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="  me-2 primary1">
                  <p className="m-0 f5">. </p>{" "}
                </div>
                <div>
                  <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                    Relationships with diverse suppliers and local artisans
                  </p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="  me-2 primary1">
                  <p className="m-0 f5">. </p>{" "}
                </div>
                <div>
                  <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                    Employing out-of-workforce women
                  </p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="  me-2 primary1">
                  <p className="m-0 f5">. </p>{" "}
                </div>
                <div>
                  <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                    Fostering age, gender, & religious diversity
                  </p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="  me-2 primary1">
                  <p className="m-0 f5">. </p>{" "}
                </div>
                <div>
                  <p className="f2 fs-16 mt-1 " style={{ color: "#839dd1" }}>
                    Providing safe drinking water through the 1% giveback
                    initiative
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex ac-jc flex-wrap w-90 w-lg-90 mx-auto"
          style={{
            marginTop: "30px",
          }}
        >
        <div className="d-block d-lg-none border-curv-img col-5 col-lg-3 mx-4 mt-2">
          <img src={dashh2} className="w-100" />
        </div>
          <div className="col-10 col-lg-6 me-md-3">
            <h5 className="sect-title  dark-blue mt-5 mb-3">Compliant</h5>
            <p className="f2 fs-16" style={{ color: "#839dd1" }}>
              To adhere to ethical standards of business and <br />
              continually improve our processes towards the highest benchmarks.
            </p>{" "}
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Strict compliance with management of information security.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Working only with suppliers who have passed the audit by the
                  RBA - world's <br />
                  largest coalition dedicated to CSR in the global supply chain.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Continually training and educating our suppliers on global
                  operating standards.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Offering only toxin free and FDA approved product options.
                </p>
              </div>
            </div>
            <div className="d-flex ">
              <div className="  me-2 primary1">
                <p className="m-0 f5">. </p>{" "}
              </div>
              <div>
                <p className="f2 fs-16 mt-1 mb-1" style={{ color: "#839dd1" }}>
                  Replacing 75% of incandescent light bulbs
                </p>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block border-curv-img col-5 col-lg-3 mx-4 my-4">
            <img src={dashh2} className="w-100" />
          </div>
        </div>
        <h5 className="sect-title text-center dark-blue mt-5">Our Givebacks</h5>
        <div className="p-2 ">
          <img src={dashh4} className="w-100" />
        </div>
        <h6 className="sect-title text-center fs-lg-24 dark-blue mt-3 mb-5 px-4">
          You are helping us bridge the gap together through our giveback
          programs.
        </h6>
        {/* <div className="d-flex ac-jc">
          <button className="get-start-btn border-0 mb-4">Read More</button>
        </div> */}
      </div>
    </>
  );
};

export default RedeemDetail;
