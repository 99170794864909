// @ts-nocheck
import React, { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { logist, product1, searchIcon } from "../../assets/img";
import { dummyOutOfStack } from "../../redux/api/DummyJson";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { Height } from "@mui/icons-material";

const InventoryInStock = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkBox, setCheckBox] = useState("");
  const navigate = useNavigate();
  const selectAllHandle = () => {
    setSelectAll(!selectAll);
  };

  const singleCheckHandler = (index) => {
    if (index === checkBox) {
      setCheckBox("");
    } else {
      setCheckBox(index);
    }
  };

  return (
    <div className="my-product mx-auto w-100  w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
      <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
        <div className="d-flex ac-js mb-3 mb-md-0">
          <div className="position-relative">
            <FilterAltIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark" />
          </div>
          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}
            >
              Status
            </p>
            <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
              <option value="all">All</option>
              <option value="Pack">Pack</option>
              <option value="Products">Products</option>
            </select>
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}
            >
              Category
            </p>
            <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
              <option value="all">All</option>
              <option value="Pack">Pack</option>
              <option value="Products">Products</option>
            </select>
          </div>
        </div>
        <div className="d-flex ac-js mb-0  ">
          <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search Products"
              name=""
              id=""
            />
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0 text-nowrap`}
            >
              Sort By
            </p>
            <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
              <option value="Newest">Newest</option>
              <option value="Oldest">Oldest</option>
              <option value=" A-Z"> A-Z</option>
              <option value=" Z-A"> Z-A</option>
            </select>
          </div>
        </div>
      </div>
      <div className="login-product d-flex flex-wrap">
        {dummyOutOfStack?.map((item, index) => {
          return (
            <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-login">
              <div className="prod-list-box">
                <div className="w-100 doneIconDesign">
                  <button className="cust-btn ">
                    <DoneIcon
                      className={`${checkBox === index || selectAll
                        ? "tickBoxDesign1"
                        : "tickBoxDesign2"
                        } rounded-5`}
                    />
                  </button>
                </div>

                <button className="cust-btn prod-list-box-img">
                  <img src={product1} style={{ height: "200px" }} alt="" />
                </button>
                <div className="btm-prod ac-jc">
                  <h3 className="text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 ps-2 pe-1  ps-lg-4 mb-0">
                    {item?.name}
                  </h3>
                  <p className="gray cust-btn fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ps-2 pe-1 mt-3 ps-lg-4 mb-0">
                    Color: {item?.color}
                  </p>
                  <p className="gray cust-btn fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ps-2 pe-1 mt-2 ps-lg-4 mb-0">
                    Size: {item?.size}
                  </p>

                  <button className="cust-btn bg-gray mt-3 text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-5 rounded-5 px-md- px-lg-4x">
                    Send Swag
                  </button>
                  <div className="w-90 d-flex ac-jc">
                    <h3 className="primary cust-btn fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 mt-3">
                      Order
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="bg-white deselectView ">
          <div className="w-75 d-flex ac-jb mt-4 px-5">
            {selectAll && (
              <button
                onClick={selectAllHandle}
                className="cust-btn primary f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17"
              >
                Delect All
              </button>
            )}
            {!selectAll && (
              <button
                onClick={selectAllHandle}
                className="cust-btn   primary f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17"
              >
                Select All
              </button>
            )}
            <button
              onClick={() => navigate("/reorder-select")}
              className={`cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3`}
            >
              Continue
            </button>
          </div>
        </div> */}
      </div>

      {/* {selectAll && (
        <div className="bg-white sideDetails">
          <p className="gray fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 px-3 py-3">
            Items
          </p>

          {dummyOutOfStack?.map((item) => {
            return (
              <div className="mt-3 px-3 d-flex">
                <img src={product1} alt="" className="itemImgDetails" />
                <div>
                  <h3 className="text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 ps-2 pe-1  ps-lg-4 mb-0">
                    {item?.name}
                  </h3>
                  <p className="gray d-flex cust-btn fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ps-2 pe-1 ps-lg-4 mb-0">
                    Color:{" "}
                    <p className="text-dark cust-btn ms-0 fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 mb-0">
                      {item?.color}
                    </p>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default InventoryInStock;
