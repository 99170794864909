// @ts-nocheck
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  closeIcon,
  logist,
  logoPay,
  product1,
  ship1,
  ship2,
} from "../assets/img";
import RequiredSummary from "../component/Order/RequiredSummary";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateQuantity from "../component/MyProductDetail/UpdateQuantity";
import SelectRecipients from "../component/SelectProduct/SelectRecipients";
import SelectProduct from "../component/SelectProduct/SelectProduct";
import ProductsAndQuantity from "../component/SelectProduct/ProductsAndQuantity";
import DoneIcon from "@mui/icons-material/Done";
import AddExistingRecipient from "../component/SelectProduct/AddExistingRecipient";
import NewContact from "../component/SelectProduct/NewContact";
import Modal from "react-bootstrap/Modal";
import AddressPopup from "../component/Popup/AddressPopup";
import {
  useAddressListOrderMutation,
  usePaymentCreateMutation,
  usePaymentStatusMutation,
  useUserAddressDeleteMutation,
} from "../redux/api/api";
import { countryList } from "../redux/api/DummyJson";
import { Update } from "@mui/icons-material";
import RequiredSummaryPayment from "../component/Order/RequiredSummaryPayment";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import DeletePopup from "../component/Popup/DeletePopup";

const SelectProductScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { error, isLoading, Razorpay } = useRazorpay();
  const project = location?.state;
  // console.log(project, "og");

  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [storagePopup, setStoragePopup] = useState(false);
  const [shipPopup, setShipPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("Add New");
  const [btnChange, setBtnChange] = useState(0);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [LandMark, setLandMark] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [num, setNum] = useState("");
  const [alternum, setAlterNum] = useState("");
  const [gst, setGst] = useState("");
  const [country, setCountry] = useState("");
  const [listState, setListState] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});

  const [billingArr, setBillingArr] = useState([]);
  const [shippingArr, setShippingArr] = useState([]);

  const [address1Err, setAddress1Err] = useState(false);
  const [firstNameErr, setfirstNameErr] = useState(false);

  const [type, setType] = useState(1);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("");

  const [selectRec, setSelectRec] = useState(false);
  const [selectSingle, setSelectSingle] = useState(false);
  const [enableReci, setEnableReci] = useState(false);
  const [addReciPopup, setAddReciPopup] = useState(false);
  const [breakdownList, setBreakdownList] = useState({});
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState();
  const [update, setUpdate] = useState({});
  const [addressId, setAddresId] = useState("");

  // RTK QUERY
  const [AddressList] = useAddressListOrderMutation();
  const [paymentCreateApi] = usePaymentCreateMutation();
  const [paymentStatusApi] = usePaymentStatusMutation();
  const [userAddressDeleteApi] = useUserAddressDeleteMutation();

  const getAddresslist = (searchs, types, orders) => {
    setSearch(searchs);
    setType(types);
    setOrder(orders);
    const formdata = new FormData();

    formdata.append("page", page);

    if (searchs) {
      formdata.append("search", searchs);
    }
    if (orders) {
      formdata.append("order", orders);
    }
    const user_details = localStorage.getItem("user_details");
    let userData = JSON.parse(user_details);
    if (userData?.id) {
      formdata.append("user_id", userData?.id);
    }
    formdata.append("project_id", project?.id);
    if (types) {
      formdata.append("type", types);
    }
    AddressList(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];

        if (Array.isArray(res?.lists)) {
          res?.lists?.map((item, ind) => {
            temp.push(item);
          });
        } else {
          res?.lists?.data?.map((item, ind) => {
            temp.push(item);
          });
        }

        if (types == 2) {
          let project_address_ids = [...billingArr];
          res?.project_address_ids?.map((item, ind) => {
            if (!project_address_ids?.includes(item)) {
              project_address_ids.push(item);
            }
          });
          setBillingArr(project_address_ids);
        }

        if (types == 1) {
          let project_address_ids = [...shippingArr];
          res?.project_address_ids?.map((item, ind) => {
            if (!project_address_ids?.includes(item)) {
              project_address_ids.push(item);
            }
          });
          setShippingArr(project_address_ids);
        }

        setList(temp);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAddresslist(search, type, order);
  }, [page]);
  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(false);
    // setStoragePopup(!storagePopup);
    setStoragePopup(false);
    setShipPopup(false);
  };

  const poppupAddressToggle = () => {
    setSelected({});
    setShowType("Add New");
    setfirstName("");
    setLastname("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setLandMark("");
    setPincode("");
    setCity("");
    setState("");
    setNum("");
    setAlterNum("");
    setCountry("");
    setGst("");
    setShow(false);
    setUpdate({});
  };

  const poppupAddressEditToggle = () => {
    setShowType("Edit");
    setShow(true);
  };

  const viewBreakdown = (item) => {
    setBreakdownList(item);
    setShowUpdate(true);
  };
  const getState = (value) => {
    setCountry(value);
    let finder = countryList?.find((i) => i?.name == value);
    setListState(finder?.states);
  };

  const getData = (item) => {
    setSelected(item);
    if (item?.first_name) {
      setfirstName(item?.first_name);
    }

    if (item?.last_name) {
      setLastname(item?.last_name);
    }

    if (item?.email_id) {
      setEmail(item?.email_id);
    }

    if (item?.address1) {
      setAddress1(item?.address1);
    }

    if (item?.address2) {
      setAddress2(item?.address2);
    }

    if (item?.landmark) {
      setLandMark(item?.landmark);
    }

    if (item?.country) {
      setCountry(item?.country);
      let finder = countryList?.find((i) => i?.name == item?.country);
      setListState(finder?.states);
    }

    if (item?.state) {
      setState(item?.state);
    }

    if (item?.city) {
      setCity(item?.city);
    }

    if (item?.pincode) {
      setPincode(item?.pincode);
    }

    if (item?.phone_no) {
      setNum(item?.phone_no);
    }

    if (item?.alter_phone_no) {
      setAlterNum(item?.alter_phone_no);
    }

    if (item?.gst_no) {
      setGst(item?.gst_no);
    }
    setUpdate(item);
    setShow(true);
  };
  // const getView = (id) => {
  //   addressView()
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.status == "success" || res?.data?.status == "success") {
  //         let data = res?.data;
  //         // let reversedComments = data?.comments?.slice().reverse();
  //         // setfirstName(reversedComments);
  //         // setLastname(data.task_date);
  //         // setAddress1(data.task_deadline_date);
  //         // setAddress2(data.project.project_code);
  //         // setAlterNum(data.assignedBy.first_name);
  //         // setNum(data.department.name);
  //         // setState(data.assignedTo.first_name);
  //         // setCountry(data.status);
  //         // setPincode(data.description);
  //         // setCity(data.completion_date);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err");
  //     });
  // };
  // useEffect(() => {

  //     getView();

  // }, []);

  const submitHandler = () => {
    let count = 1;
    if (billingArr?.length <= 0) {
      count = count + 1;
    }

    if (shippingArr?.length <= 0) {
      count = count + 1;
    }

    if (count == 1) {
      let temp = [...billingArr, ...shippingArr];

      let formData = new FormData();
      formData.append("project_id", project?.id);
      formData.append("amount", project?.total);

      temp?.map((item, ind) => {
        formData.append(`user_address_ids[${ind}]`, item);
      });

      dispatch(saveLoader(true));
      paymentCreateApi(formData)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          handlePayment(res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    } else {
      toast.error("Please Select Billing and Shipping Address");
    }
  };

  const handlePayment = (res) => {
    try {
      const { amount, key, order_id } = res;
      const options: RazorpayOrderOptions = {
        key: key,
        amount: amount, // Amount in paise
        currency: "INR",
        name: "Merchhq",
        description: "Merchhq",
        order_id: order_id, // Generate order_id on server
        image: logoPay,
        handler: (response) => {
          if (response.razorpay_order_id) {
            submitPaymentHandler("success", response?.razorpay_order_id);
          } else {
            submitPaymentHandler("failed", response?.razorpay_order_id);
          }
          // console.log("response", response);
        },
        prefill: {
          name: project?.user_details?.first_name,
          email: project?.user_details?.email,
          contact: project?.user_details?.mobile,
        },
        theme: {
          color: "#0d7aa8",
        },
      };

      const razorpayInstance = new Razorpay(options);

      razorpayInstance.on("payment.failed", (response) => {
        if (response?.error?.reason) {
          alert("Payment Failed Please try again latter");
          // submitPaymentHandler(
          //   "failed",
          //   response?.error?.metadata?.order_id
          //     ? response?.error?.metadata?.order_id
          //     : order_id
          // );
        }
        // console.log("Payment Failure Response:", response);
      });

      razorpayInstance.open();
    } catch (errorMsg) {
      console.log("error", errorMsg);
    }
  };

  const submitPaymentHandler = (status, razorpay_order_id) => {
    let formData = new FormData();
    formData.append("order_id", razorpay_order_id);
    formData.append("status", status);
    dispatch(saveLoader(true));
    paymentStatusApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          navigate("/my-order");
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const getDelete = (id) => {
    const formdata = new FormData();
    formdata.append("user_address_id", id);
    dispatch(saveLoader(true));
    userAddressDeleteApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setShowDelete(false);
        toast.success(res?.message);
        getAddresslist(search, type, order);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err, "err");
      });
  };

  const deleteToggle = (type) => {
    setShowDelete(!showDelete);
    if (type == 1) {
      getDelete(addressId);
    }
  };

  const showDeletePopUp = (id) => {
    setAddresId(id);
    setShowDelete(true);
  };

  return (
    <div className="tl-shipment-screen">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
        <div className="product-shipment d-flex flex-column flex-lg-row justify-content-between mt-3 mb-5">
          <div className="d-flex ac-jb w-100">
            <div className="w-100 d-flex ac-js w-100">
              <button
                className={`${
                  btnChange == 0 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-14 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(0);
                  getAddresslist(search, 1, order);
                }}
              >
                Shipping Address
              </button>
              <button
                className={`${
                  btnChange == 1 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-14 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(1);
                  getAddresslist(search, 2, order);
                }}
              >
                Billing Address
              </button>
            </div>
          </div>
        </div>

        <SelectRecipients
          getAddresslist={getAddresslist}
          order={order}
          search={search}
          type={type}
          addReciPopup={addReciPopup}
          setAddReciPopup={setAddReciPopup}
          setShow={setShow}
          setList={setList}
          list={list}
          firstName={firstName}
          setfirstName={setfirstName}
          lastName={lastName}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          LandMark={LandMark}
          setLandMark={setLandMark}
          state={state}
          country={country}
          setCountry={setCountry}
          setState={setState}
          city={city}
          setCity={setCity}
          pincode={pincode}
          setPincode={setPincode}
          num={num}
          setNum={setNum}
          alternum={alternum}
          setAlterNum={setAlterNum}
          gst={gst}
          setGst={setGst}
          countryList={countryList}
          setListState={setListState}
          listState={listState}
          selected={selected}
          setSelected={setSelected}
          getData={getData}
          update={update}
          setUpdate={setUpdate}
          selected={selected}
          setSelected={setSelected}
          poppupAddressEditToggle={poppupAddressEditToggle}
          billingArr={billingArr}
          setBillingArr={setBillingArr}
          shippingArr={shippingArr}
          setShippingArr={setShippingArr}
          setPage={setPage}
          page={page}
          pagenation={pagenation}
          showDeletePopUp={showDeletePopUp}
        />
      </div>

      {/* <ProductsAndQuantity
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
      /> */}
      <RequiredSummaryPayment
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
        showUpdate={setShowUpdate}
        openSummeryHandler={openSummeryHandler}
        list={project}
        viewBreakdown={viewBreakdown}
        isLoading={isLoading}
        submitHandler={submitHandler}
        error={error}
      />

      {/* <AddExistingRecipient
        showUpdate={showUpdate}
        showClickHandler={showClickHandler}
      /> */}
      <UpdateQuantity
        showUpdate={showUpdate}
        showUpdateClick={setShowUpdate}
        showClickHandler={showClickHandler}
        breakdownList={breakdownList}
      />
      <NewContact
        showUpdate={showContact}
        showClickHandler={showClickHandler}
        setShow={setShow}
      />
      {addReciPopup && (
        <div className="custPopup p-3 rounded-4">
          <button
            className="cust-btn"
            onClick={() => {
              setShowUpdate(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Select from Existing</h6>
          </button>
          <button
            className="cust-btn mt-2"
            onClick={() => {
              setShowContact(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Add New Contact</h6>
          </button>
        </div>
      )}

      {show && (
        <AddressPopup
          poppupAddressToggle={poppupAddressToggle}
          showType={showType}
          firstName={firstName}
          setfirstName={setfirstName}
          lastName={lastName}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          LandMark={LandMark}
          setLandMark={setLandMark}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
          pincode={pincode}
          setPincode={setPincode}
          num={num}
          setNum={setNum}
          alternum={alternum}
          setAlterNum={setAlterNum}
          gst={gst}
          setGst={setGst}
          type={type}
          setType={setType}
          project={project}
          setListState={setListState}
          listState={listState}
          selected={selected}
          setSelected={setSelected}
          getData={getData}
          update={Update}
          country={country}
          setCountry={setCountry}
          setUpdate={setUpdate}
          getAddresslist={getAddresslist}
        />
      )}

      {showDelete && <DeletePopup deleteToggle={deleteToggle} />}
    </div>
  );
};

export default SelectProductScreen;
