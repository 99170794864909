// @ts-nocheck
import React, { useEffect, useState } from "react";
import { bg1, logo, logoPay } from "../assets/img";
import ChangePassword from "../component/Auth/ChangePassword";
import ForgotPassword from "../component/Auth/ForgotPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  usePaymentCreateLinkMutation,
  usePaymentStatusMutation,
} from "../redux/api/api";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const PayMethod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, isLoading, Razorpay } = useRazorpay();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // RTK QUERY
  const [paymentCreateApi] = usePaymentCreateLinkMutation();
  const [paymentStatusApi] = usePaymentStatusMutation();

  useEffect(() => {
    if (searchParams?.get("_p")) {
      // Decode the String
      var decodedStringAtoB = atob(searchParams?.get("_p"));
      let user_data = JSON.parse(decodedStringAtoB);
      submitHandler(user_data);
    }
  }, []);

  const submitHandler = (user_data) => {
    let formData = new FormData();
    formData.append("project_id", user_data?.project_id);
    formData.append("amount", user_data?.total_amount);
    formData.append("user_id", user_data?.user_id);

    dispatch(saveLoader(true));
    paymentCreateApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        handlePayment(res, user_data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const handlePayment = (res, user_data) => {
    try {
      const { amount, key, order_id } = res;
      if (order_id && key) {
        const options: RazorpayOrderOptions = {
          key: key,
          amount: amount, // Amount in paise
          currency: "INR",
          name: "Merchhq",
          description: "Merchhq",
          order_id: order_id, // Generate order_id on server
          image: logoPay,
          handler: (response) => {
            if (response.razorpay_order_id) {
              toast.success("Payment Success");
              navigate("/my-order");
              // submitPaymentHandler("success", response?.razorpay_order_id);
            } else {
              toast.error("Payment Failed");
              // submitPaymentHandler("failed", response?.razorpay_order_id);
            }
            // console.log("response", response);
          },
          prefill: {
            name: user_data?.first_name,
            email: user_data?.email,
            contact: user_data?.mobile,
          },
          theme: {
            color: "#0d7aa8",
          },
        };

        const razorpayInstance = new Razorpay(options);

        razorpayInstance.on("payment.failed", (response) => {
          if (response?.error?.reason) {
            alert("Payment Failed Please try again latter");
            // submitPaymentHandler(
            //   "failed",
            //   response?.error?.metadata?.order_id
            //     ? response?.error?.metadata?.order_id
            //     : order_id
            // );
          }
          // console.log("Payment Failure Response:", response);
        });

        razorpayInstance.open();
      }
    } catch (errorMsg) {
      console.log("error", errorMsg);
    }
  };

  const submitPaymentHandler = (status, razorpay_order_id) => {
    let formData = new FormData();
    formData.append("order_id", razorpay_order_id);
    formData.append("status", status);
    dispatch(saveLoader(true));
    paymentStatusApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          navigate("/my-order");
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  return (
    <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center ">
      <h5
        onClick={() => navigate("/")}
        className="sect-title mb-0 primary log-logo"
      >
        {/* Merchhq */}
        <img src={logo} className="logo" alt="" style={{ width: "210px" }} />
      </h5>

      <img src={bg1} alt="" className="bg1" />

      <div className="rit-auth forgot-auth-rt"></div>
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <img src={bg1} alt="" className="bg2" />
    </div>
  );
};

export default PayMethod;
