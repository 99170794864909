// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  closeIcon,
  google,
  noLogo,
  product,
  product1,
  twitter,
} from "../../assets/img";
// import "./styles.css";
import { image_slider } from "../../redux/api/DummyJson";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 50px;qq  
  border: 1px solid #00adb7;
  border-radius: 15px;
  :hover {
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.55), 0 14px 18px rgba(0, 0, 0, 0.55);
  }
`;

const Image = styled.img.attrs((props) => ({
  // src: "https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823",
  src: props.source,
}))``;

const Target = styled(Image)`
  position: absolute;
  left: ${(props) => props.offset.left}px;
  top: ${(props) => props.offset.top}px;
  opacity: ${(props) => props.opacity};
`;

const ProductZoom = ({
  data,
  pendingitem,
  type,
  artwrk,
  setArtWork,
  setArtId,
}) => {
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const [image, setImage] = useState({});
  const filter = () => {
    if (pendingitem) {
      const id = data?.project_request_details?.find(
        (item) => item == pendingitem
      );
      // console.log(id, "new");

      setImage(id);
    }
  };
  const filterview = () => {
    if (pendingitem) {
      const id = data?.projectDetail?.find((item) => item == pendingitem);
      // console.log(id, "new");

      setImage(id);
    }
  };
  useEffect(() => {
    if (type) {
      filterview();
    } else {
      filter();
    }
  }, [pendingitem]);

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio =
      (targetRect.height - containerRect.height) / sourceRect.height;

    const left = Math.max(
      Math.min(e.pageX - sourceRect.left, sourceRect.width),
      0
    );
    const top = Math.max(
      Math.min(e.pageY - sourceRect.top, sourceRect.height),
      0
    );

    setOffset({
      left: left * -xRatio,
      top: top * -yRatio,
    });
  };

  return (
    <div className="wi-100 w-sm-100 w-50">
      <Container
        className="border-0 p-0 "
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <Image
          ref={sourceRef}
          className="show-img w-100"
          alt="source"
          source={
            type
              ? artwrk?.image_url
                ? artwrk?.image_url
                : noLogo
              : pendingitem?.selected_image_url
              ? pendingitem?.selected_image_url
              : artwrk?.image_url
              ? artwrk?.image_url
              : noLogo
          }
        />
        <Target
          ref={targetRef}
          alt="target"
          opacity={opacity}
          offset={offset}
          // source="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
          source={
            type
              ? artwrk?.image_url
                ? artwrk?.image_url
                : noLogo
              : pendingitem?.selected_image_url
              ? pendingitem?.selected_image_url
              : artwrk?.image_url
              ? artwrk?.image_url
              : noLogo
          }
        />
      </Container>
      <div className="product-slide">
        {/* <ImageGallery
            items={images}
            showFullscreenButton={true}
            slideOnThumbnailOver={true}
            showIndex={true}
          /> */}

        <div
          className="d-flex ac  mt-4 mob-carous-pop"
          style={{ width: "400px", overflow: "scroll" }}
        >
          {pendingitem?.artworks?.map((item, ind) => {
            return (
              <div
                className="down_imgs m-1"
                key={ind}
                role={"button"}
                onClick={() => {
                  setArtWork(item);
                  setArtId(item?.id);
                }}
                style={
                  artwrk?.id == item?.id
                    ? { border: "1px solid black" }
                    : { border: "none" }
                }
              >
                <img
                  src={item.image_url ? item.image_url : noLogo}
                  style={{
                    padding: "10px",
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </div>
            );
          })}
        </div>
        <KeyboardDoubleArrowRightIcon className="rightArrow" />
      </div>
    </div>
  );
};

export default ProductZoom;
