// @ts-nocheck
import React from "react";
import { whatsNew } from "../../assets/img";
import { Link, useNavigate } from "react-router-dom";

const WhatsNew = () => {
  const navigate = useNavigate()
  return (
    <div className="whats-new mb-4">
      <div className="box bg-lt-blue px-3 px-lg-4 py-2 mb-3">
        <h5 className="text-dark f4 fs-xl-25 mt-lg-4 mb-2 mt-2">Responsible HQ</h5>
        <h5 className="text-dark f2 fs-13 fs-lg-16 fs-xl-17 mb-lg-3 mt-0">
          Your merch isn't just making an impression, it's making the planet happier, one responsible choice at a time.
        </h5>
        <Link to='/sustainability' className="get-start-view text-white border-0 mt-xl-4 mb-2 mb-xl-4">
          Know More
        </Link>
      </div>
      <div className="box bg-lt-blue px-3 px-lg-4 py-2">
        <img src={whatsNew} className="mx-auto d-flex mt-lg-3" alt="" />
        <h5 className="text-dark f4 fs-xl-25 mt-lg-4 mb-lg-2">Orders</h5>
        <h5 className="text-dark f2 fs-13 fs-lg-16 fs-xl-17 mb-lg-4">
          View all your merch made at the HQ
        </h5>
        <button onClick={() => navigate('/dashboard/lets-start')} className="get-start-view text-white border-0 mt-lg-3 mb-2">
          View
        </button>
      </div>
    </div>
  );
};

export default WhatsNew;
