// @ts-nocheck
import React from "react";
import OwlCarousel from "react-owl-carousel";
import {
  abt10,
  abt11,
  abt12,
  abt6,
  abt7,
  abt8,
  abt9,
  inst1,
  inst2,
  inst3,
  inst4,
  inst5,
  inst6,
  inst7,
  person1,
} from "../../../assets/img";
import { options } from "../../../redux/api/constants";

const ExpertTeam = () => {
  return (
    <div>
      <div className="meet-circle mt-4 w-xs-100 w-sm-90 w-md-75 mx-auto  mb-md-3 ac-jb d-flex flex-column">
        {/* <p className="sect-title text-center fs-18 w-80 m-auto">
        We have taken our 20+ years of experience in the merchandise industry to
        bring you only the best of the best. So, let's get down to business
      </p> */}
        {/* <p className="parag text-center">
        We have taken our 20+ years of experience in the merchandise industry to
        bring you only the best of the best. So, let's get down to business
      </p> */}

        <OwlCarousel {...options}>
          <div className=" bg-team-border">
            <img src={abt7} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-12 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Merchandise Consultant
            </h6>
            <h5 className="f4 text-black fs-12 fs-md-16 fs-lg-17 fs-xl-20">
              Mansi Kumar
            </h5>
          </div>
          <div className=" bg-team-border">
            <img src={abt8} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-12 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Merchandise Consultant
            </h6>
            <h5 className="f4 text-black fs-12 fs-md-16 fs-lg-17 fs-xl-20">
              Kenneth Lemuel
            </h5>
          </div>
          <div className=" bg-team-border">
            <img src={abt9} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-12 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Product Strategist
            </h6>
            <h5 className="f4 text-black fs-12 fs-md-16 fs-lg-17 fs-xl-20">
              Neha S
            </h5>
          </div>
          <div className=" bg-team-border">
            <img src={abt10} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-11 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Strategic Innovation Consultant
            </h6>
            <h5 className="f4 text-black fs-11 fs-md-16 fs-lg-17 fs-xl-20">
              Raghavendra Adkoli
            </h5>
          </div>
          <div className=" bg-team-border">
            <img src={abt11} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-12 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Merchandise Design Expert
            </h6>
            <h5 className="f4 text-black fs-12 fs-md-16 fs-lg-17 fs-xl-20">
              Vijay Naik
            </h5>
          </div>
          <div className=" bg-team-border">
            <img src={abt12} className="w-40" alt="team" />
            <h6 className="f2 primary1 fs-12 fs-md-15 fs-lg-16 fs-xl-18 mt-2 mt-lg-3">
              Platform Strategist
            </h6>
            <h5 className="f4 text-black fs-12 fs-md-16 fs-lg-17 fs-xl-20">
              Rahul V
            </h5>
          </div>
        </OwlCarousel>
        <p className="sect-title text-center px-3 px-lg-3 py-lg-4 mt-4 mt-md-0">
          We have taken our 20+ years of experience in the merchandise industry
          to bring you only the best of the best. So, let's get down to business
        </p>
        <button className="get-start-btn mt-0 border-0 mb-5">
          Get Started
        </button>
      </div>

      {/* <h4 className="sect-title mt-3 mt-xl-5">Our Story</h4>
      <p className="parag text-justify">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis
        tempore, culpa, molestiae nemo commodi Lorem ipsum dolor sit amet,
        consectetur adipisicing elit. Corporis tempore, culpa, molestiae nemo
        commodi Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Corporis tempore, culpa, molestiae nemo commodi Lorem ipsum dolor sit
        amet, consectetur adipisicing elit. Corporis tempore, culpa, molestiae
        nemo commodi
      </p> */}
    </div>
  );
};

export default ExpertTeam;
