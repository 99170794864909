import React, { useState } from "react";
import InventoryContact from "../component/InventoryDetail/InventoryContact";
import InventoryShipment from "../component/InventoryDetail/InventoryShipment";
import InventoryTab from "../component/InventoryDetail/InventoryTab";

const MyInventoryDetail = () => {
  const [shipment, setShipment] = useState(false);
  return (
    <div className="my-product mx-auto w-95 w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
      <InventoryTab
        shipment={shipment}
        title="Inventory"
        btnText="Design Products"
      />
      {shipment ? (
        <InventoryShipment shipment={shipment} />
      ) : (
        <InventoryContact shipment={shipment} />
      )}
    </div>
  );
};

export default MyInventoryDetail;
