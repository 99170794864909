import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../../redux/api/constants";
import DoneIcon from "@mui/icons-material/Done";
import {
  Searcher,
  build_Pack,
  bulkPack,
  circle,
  circle1,
  circle2,
  circle3,
  circle4,
  closeIcon,
  p1,
  p2,
  p3,
  p4,
  presetPack,
} from "../../../assets/img";

const ProductPopupComponent = ({
  type,
  showPopupHander,
  viewList,
  method,
  selectedIds,
  setSelectedIds,
  setCount,
  count,
  productPrice,
  imagesid,
  setTick,
  packsPrice,
  getAddToCart,
  colorcount,
  setColorCount,
  setBigImg,
  bigImg,
  defaultimg,
  material,
  setMaterial,
  setSize,
  size,
  setStyle,
  style,
  selectedColorId,
  setSelectedColorId,
  selectColorId,
  setSelectColorId,
  varients,
  pack,
  calculations,
  setCartData,
  cartData,
  userCartID,
  deleteCart,
  cartBtn,
  deleteBtn,
  varientsNew,
  updateLocalCart,
  qtyBtn,
}) => {
  // const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // QTY HANDLER
  const increment = (id, type) => {
    let count_value = cartData?.[id]?.count
      ? +cartData?.[id]?.count
      : +cartData?.[id]?.count_value;

    let pack_value = cartData?.[id]?.pack;

    if (type == "decrement") {
      if (+count_value > 1) {
        calculations(+count_value, id, pack_value, type);
      }
    } else {
      if (+count_value < 10) {
        calculations(+count_value, id, pack_value, type);
      }
    }
  };

  // NO OF COLOR HANDLER
  const decrementColor = (id, type) => {
    let obj = { ...cartData };

    let final = {
      ...obj[id],
    };

    let noOfColors = +obj?.[id]?.no_of_colors;

    if (type == "decrement") {
      if (noOfColors > 1) {
        if (cartData?.[id]?.cart_id) {
          varientsNew(
            id,
            "",
            cartData?.[id]?.discount_value,
            selectColorId,
            noOfColors - 1,
            "logo"
          );
        } else {
          final.no_of_colors = noOfColors - 1;
        }
      }
    } else {
      if (noOfColors < 10) {
        if (cartData?.[id]?.cart_id) {
          varientsNew(
            id,
            "",
            cartData?.[id]?.discount_value,
            selectColorId,
            noOfColors + 1,
            "logo"
          );
        } else {
          final.no_of_colors = noOfColors + 1;
        }
      }
    }
    setCartData((val) => ({ ...val, [id]: final }));
  };

  const increments = (id) => {
    if (count < 10) {
      setCount(count + 1);
      calculations(count - 1, id);
    }
  };
  const decrement = (id) => {
    if (count > 1) {
      setCount(count - 1);
      calculations(count - 1, id);
    }
  };
  const incrementColor = () => {
    if (colorcount < 10) {
      setColorCount(colorcount + 1);
    }
  };
  const decrementColors = () => {
    if (colorcount > 1) {
      setColorCount(colorcount - 1);
    }
  };
  const setid = () => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(imagesid?.id);
    }
    setSelectedIds(temp);
  };

  useEffect(() => {
    setid();
  }, [count]);

  const handleColorClick = (colorname, imageUrl, ind, id, type) => {
    setSelectedColorId(colorname?.color?.name);
    setSelectColorId(colorname?.color?.id);
    setSelectedImage(imageUrl);
    setBigImg(ind);
    if (cartData?.[id]?.cart_id) {
      varientsNew(
        id,
        "",
        cartData?.[id]?.discount_value,
        colorname?.color?.id,
        "",
        "color"
      );
    }
  };

  const addToPack = () => {
    const temp = [];
    temp.push(imagesid?.id);
    setTick(temp);
    showPopupHander();
  };

  // console.log("cartBtn", cartBtn, deleteBtn);
  // console.log("viewList", viewList);
  // console.log(bigImg,"bigImg");

  const calsum = (amount1, amount2, id_) => {
    let qty_ = cartData?.[id_]?.count
      ? +cartData?.[id_]?.count
      : +cartData?.[id_]?.count_value;
    let mul = +amount1 * +amount2;
    let final = +mul * +qty_;
    return final;
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />

      {viewList?.map((list, ind) => {
        return (
          <div
            className="center-content d-flex flex-column flex-lg-row"
            key={ind}
          >
            <button
              onClick={() => {
                showPopupHander();
                setCount(1);
                setColorCount(1);
              }}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="Close" />
            </button>

            <div className="left-popup">
              {/* <div className="position-relative" style={{ width: "200px" }}> */}
              {/* <img
                  className="img_up_cont"
                  src={list?.colorImages?.[bigImg]?.image_url}
                /> */}
              {/* </div> */}
              <div className="">
                <div className="d-flex ac-jc">
                  <div className="imges">
                    <img
                      style={{
                        // height: "200px",
                        // width: "200px",
                        objectFit: "cover",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      // src={p1}
                      src={list?.images?.[bigImg]?.image_url}
                    />

                    {/* {list?.colorImages?.map((img, imgInd) => (
                  <div className="gap-3 d-flex ac-j w-100">
                    <div
                      key={imgInd}
                      id={img?.id}
                      class="add_img d-flex ac-jc"
                      onClick={() => setBigImg(imgInd)}
                    >
                      <img
                        src={img?.image_url}
                        class="cp add_img object-fit-fill"
                      />
                    </div>
                  </div>
                ))} */}
                  </div>
                </div>
                <div className="d-flex ac-jc">
                  <div
                    className="d-flex  mt-4 mob-carous-pop"
                    style={{ width: "400px", overflow: "scroll" }}
                  >
                    {list?.images?.map((img, imgInd) => {
                      return (
                        <div className="down_imgs">
                          <img
                            key={imgInd}
                            id={img?.id}
                            onClick={() => {
                              handleColorClick(
                                img,
                                "",
                                imgInd,
                                list?.id,
                                "image"
                              );
                              setBigImg(imgInd);
                            }}
                            src={img?.image_url}
                            // src={p3}
                            style={{
                              padding: "10px",
                              height: "100px",
                              width: "100px",
                              objectFit: "cover",
                              borderRadius: "15px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="rit-pop">
              <h2 className="pe-lg-5 text-center text-lg-start">
                {list?.name}
              </h2>
              {/* <p className="parag">Designer</p> */}

              {list?.colors?.length > 0 && (
                <h3 className="mt-3">Select Color</h3>
              )}

              {list?.images?.length > 0 && (
                <div className="d-flex ac-js">
                  {list?.images?.map((col, colInd) => {
                    return (
                      <button
                        key={colInd}
                        style={{ background: col?.color?.hex_code }}
                        className="cust-btn color-box d-flex ac-jc"
                        onClick={() =>
                          handleColorClick(col, "", colInd, list?.id)
                        }
                      >
                        {selectedColorId === col?.color?.name ? (
                          <DoneIcon className="text-white fs-25" />
                        ) : (
                          ""
                        )}
                      </button>
                    );
                  })}
                </div>
              )}

              <div>
                {type !== "induvial" && size && (
                  <div>
                    <div>
                      <label>sizes</label>
                    </div>
                    <select
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      value={size}
                    >
                      {list?.sizes?.map((size, ind) => {
                        return (
                          <option value={size?.value}>{size?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {type !== "induvial" && material && (
                  <div>
                    <div>
                      <label>materials</label>
                    </div>
                    <select
                      onChange={(e) => {
                        setMaterial(e.target.value);
                      }}
                      value={material}
                    >
                      {list?.materials?.map((material, ind) => {
                        return (
                          <option value={material?.value}>
                            {material?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {type !== "induvial" && style && (
                  <div>
                    <div>
                      <label>Style</label>
                    </div>

                    <select
                      onChange={(e) => {
                        setStyle(e.target.value);
                      }}
                      value={style}
                    >
                      {list?.styles?.map((style, ind) => {
                        return (
                          <option value={style?.value}>{style?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="d-sm-flex  pe-lg-5 mt-2 mt-lg-3">
                <div className="me-3 me-lg-0">
                  <h3>
                    Quantity
                    <span className="primary fs-19 me-4"> (max 10)</span>
                  </h3>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 me-2"
                      onClick={() => {
                        // decrement(list?.id);
                        increment(list?.id, "decrement");
                      }}
                      // disabled={qtyBtn}
                    >
                      -
                    </button>
                    <h5 className=" fs-25 f5 px-2 mb-0">
                      {cartData?.[list?.id]?.count
                        ? cartData?.[list?.id]?.count
                        : cartData?.[list?.id]?.count_value}
                    </h5>
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 mx-2"
                      onClick={() => {
                        increment(list?.id, "increment");
                      }}
                      // disabled={qtyBtn}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="ps-sm-5">
                  <h3>No. of Colors in logo</h3>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn mb-0 me-2"
                      onClick={() => decrementColor(list?.id, "decrement")}
                    >
                      -
                    </button>
                    <h5 className=" fs-25 f5 px-2 mb-0">
                      {cartData?.[list?.id]?.no_of_colors}
                    </h5>
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn mb-0 mx-2"
                      onClick={() => decrementColor(list?.id, "increment")}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex ac-js">
                <h2 className="op-80">
                  ₹
                  {cartData?.[list?.id]?.packs_price
                    ? +cartData?.[list?.id]?.packs_price
                    : +cartData?.[list?.id]?.for_price}
                  /item{" "}
                  <span>
                    {+cartData?.[list?.id]?.pack} Kits = ₹
                    {calsum(
                      cartData?.[list?.id]?.packs_price
                        ? +cartData?.[list?.id]?.packs_price
                        : +cartData?.[list?.id]?.for_price,
                      +cartData?.[list?.id]?.pack,
                      list?.id
                    )}
                    {/* {cartData?.[list?.id]?.packs_price
                      ? +cartData?.[list?.id]?.packs_price *
                        +cartData?.[list?.id]?.pack
                      : +cartData?.[list?.id]?.for_price *
                        +cartData?.[list?.id]?.pack} */}
                  </span>
                </h2>
              </div>
              {/* {method == "final" ? (
                <div className="d-flex gap-2">
                  {" "}
                  <button
                    className="get-start-btn border-0 mb-2"
                    onClick={() => {
                      // getAddToCart(list?.id);
                      varients(list?.id);
                    }}
                  >
                    Update Pack
                  </button>
                  <button
                    className="get-start-btn border-0 mb-2"
                    onClick={() => {
                      // getAddToCart(list?.id);
                      deleteCart(userCartID, list?.id);
                    }}
                  >
                    Remove Pack
                  </button>
                </div>
              ) : ( */}
              <button
                className={`get-start-btn border-0 mb-2 ${
                  cartBtn ? "opacity-50" : deleteBtn ? "opacity-50" : ""
                }`}
                onClick={() => {
                  // getAddToCart(list?.id);
                  if (cartData?.[list?.id]?.cart_id) {
                    deleteCart(cartData?.[list?.id]?.cart_id, list?.id);
                  } else {
                    varients(list?.id);
                  }
                }}
                disabled={cartBtn ? true : deleteBtn ? true : false}
              >
                {cartData?.[list?.id]?.cart_id ? "Remove Kit" : "Add to Kit"}
              </button>
              {/* )} */}
              <h3 className="mt-3 mb-1">Description</h3>
              <p className="parag mb-4 px-3 ps-lg-0 text-center text-lg-start">
                {list?.description}
              </p>

              {list?.description_points?.length > 0 && (
                <h3 className="mt-3 mb-2">About this item</h3>
              )}
              {list?.description_points?.map((points, pinx) => {
                return (
                  <div key={pinx} className="d-flex gap-2">
                    <p style={{ fontSize: "20px" }}>&#8702;</p>
                    <p className="parag_popup mb-4 px-3 ps-lg-0 text-center text-md-start">
                      {points}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductPopupComponent;
