import React, { useState } from "react";
// import { delivery_product } from "../../../assets/img";
import { cloudUpload, delivery_product } from "../../../assets/img";
import DatePicker from "react-datepicker";
import ErrorIcon from "@mui/icons-material/Error";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import "react-datepicker/dist/react-datepicker.css";
import {
  ArrowBackIosNewOutlined,
  DateRangeOutlined,
} from "@mui/icons-material";

const TellProject = ({
  method,
  showContactHandler,
  handleContinue,
  date,
  setDate,
  dateErr,
  comments,
  setComments,
  commentsErr,
  primaryLogo,
  primaryLogoErr,
  setPrimaryLogo,
  secondryLogo,
  secondryLogoErr,
  setSecondryLogo,
  manyPacks,
  manyPacksErr,
  setManyPacks,
  budgetPack,
  setBugetPack,
  budgetPackErr,
  submitHandle,
  btn,
}) => {
  const [startDate, setStartDate] = useState();
  const [uploadImage, setUploadImage] = useState();
  const fileChange = (e) => {
    setUploadImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files);
  };
  return (
    <>
      <div className="d-flex ac-jb">
        <h4 className="sect-title">2. Tell us about your project</h4>
        <button
          onClick={showContactHandler}
          className="cust-btn f3 edit-prev fs-13 fs-md-16 fs-lg-18 d-flex ac-jc"
        >
          <ModeEditIcon className="fs-12 fs-md-16 fs-lg-18 me-1" />
          Edit Previous
        </button>
      </div>
      {method !== "bulk" && (
        <div className="d-flex flex-wrap">
          <div className="input-box wi-100 w-md-50 mb-2">
            <p>Number of kits you are looking to get.</p>
            <input
              type="number"
              className="py-2"
              placeholder="e.g : 123"
              onChange={(e) => setManyPacks(e.target.value)}
              value={manyPacks}
            />
            {manyPacks?.length == 0 && manyPacksErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Many Kit</p>
              </div>
            )}
          </div>
          <div className="input-box wi-100 w-md-50 mb-2">
            <p>Your budget per kit.</p>
            <input
              type="number"
              className="py-2"
              placeholder="e.g : 10000"
              onChange={(e) => setBugetPack(e.target.value)}
              value={budgetPack}
            />
            {budgetPack?.length == 0 && budgetPackErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Budget Per Kit</p>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="input-box w-100 mb-2 position-relative">
        <p>Do you need these by a certain date?</p>
        <div className="w-100 d-flex">
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="eg : DD/MM/YYYY"
          />
          <DateRangeOutlined className="primary1 fs-22 fs-sm-23 fs-md-24 fs-xl-25 position-absolute date-align" /> */}
          <input
            type={"date"}
            placeholder={"DD/MM/YYYY"}
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
        </div>
        {date?.length == 0 && dateErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Select Date</p>
          </div>
        )}
      </div>
      <div className="input-box wi-100 w-md-100 mb-2">
        <p>Any additional Notes or Comments you like to add?</p>
        <input
          type="text"
          placeholder="Insert card copy, special requests, or anything we missed"
          className="py-2"
          onChange={(e) => setComments(e.target.value)}
          value={comments}
        />
        {comments?.length == 0 && commentsErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Notes or Comments</p>
          </div>
        )}
      </div>
      <div className="input-box wi-100 w-md-100 mb-2">
        <p className="text-dark">
          Upload the logo you'd like to put on your product
        </p>
      </div>
      <div className="d-flex flex-wrap flex-column flex-md-row ac-jb delivery_product bg-lt-blue p-2 px-md-4 px-lg-5 py-md-3 w-100">
        <div className="file-upload-input d-flex ac-js">
          <img src={delivery_product} alt="" />
          <div className="text-start ps-3">
            <h6 className="primary">
              Upload Vector file for the best quality and faster turnaround
            </h6>
            <p className="priamry1">
              The file should end in: .svg, .eps, .ai, .pdf
            </p>
          </div>
        </div>
        <h6 className="mt-2 mt-md-0 mb-0">Learn more</h6>
      </div>
      <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-4 mt-lg-4">
        <div className="wi-100 w-sm-47 d-flex ac-js mb-3 mb-sm-2">
          <label className="w-100 position-relative">
            <input
              onChange={(e) => setPrimaryLogo(e.target.files[0])}
              type="file"
              id="images"
              required
            />
            {primaryLogo ? (
              <div className="prev-img">
                <img
                  src={
                    primaryLogo?.name
                      ? URL.createObjectURL(primaryLogo)
                      : primaryLogo
                  }
                  alt=""
                />
              </div>
            ) : (
              <div className="log-upload-box position-absolute">
                <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                  Primary Logo
                </p>
                <img src={cloudUpload} className="" alt="" />
                <p className="text-dark text-center fs-13 fs-md-14">
                  Drag and drop to upload or Click <br />
                  here to upload
                </p>
                {primaryLogo?.length == 0 && primaryLogoErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Primary Logo</p>
                  </div>
                )}
              </div>
            )}
          </label>
        </div>
        <div className="wi-100 w-sm-47 d-flex ac-js mb-3 mb-sm-2">
          <label className="w-100 position-relative">
            <input
              onChange={(e) => setSecondryLogo(e.target.files[0])}
              type="file"
              id="images"
              required
            />
            {secondryLogo ? (
              <div className="prev-img">
                <img
                  src={
                    secondryLogo?.name
                      ? URL.createObjectURL(secondryLogo)
                      : secondryLogo
                  }
                  alt=""
                />
              </div>
            ) : (
              <div className="log-upload-box position-absolute">
                <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                  Secondary Logo
                </p>
                <img src={cloudUpload} className="" alt="" />
                <p className="text-dark text-center fs-13 fs-md-14">
                  Drag and drop to upload or Click
                  <br /> here to upload
                </p>
                {secondryLogo?.length == 0 && secondryLogoErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Secondary Logo</p>
                  </div>
                )}
              </div>
            )}
          </label>
        </div>
        <button
          // onClick={() => handleContinue()}
          onClick={() => submitHandle()}
          className={`get-start-btn border-0 mb-2 w-auto px-3 px-md-4 ${
            btn && "opacity-50"
          }`}
          disabled={btn}
        >
          Submit mockup request
        </button>
      </div>
    </>
  );
};

export default TellProject;

// import React, { useState } from "react";
// // import { delivery_product } from "../../../assets/img";
// import { cloudUpload, delivery_product } from "../../../assets/img";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
// import {
//   ArrowBackIosNewOutlined,
//   DateRangeOutlined,
// } from "@mui/icons-material";
// import { lineHeight } from "@mui/system";

// const TellProject = ({ showContactHandler }) => {
//   const [startDate, setStartDate] = useState();
//   const [uploadImage, setUploadImage] = useState();
//   const fileChange = (e) => {
//     setUploadImage(URL.createObjectURL(e.target.files[0]));
//     console.log(e.target.files);
//   };
//   return (
//     <>
//       <h4 className="sect-title">Delivery Information</h4>
//       <div className="d-flex flex-wrap">
//         <div className="input-box wi-100 w-md-50 mb-2">
//           <p>Name</p>
//           <input type="name" className="py-2" placeholder="Charles" />
//         </div>
//         <div className="input-box wi-100 w-md-50 mb-2">
//           <p>Mobile Number</p>
//           <input type="number" className="py-2" placeholder="98765 43210" />
//         </div>
//       </div>
//       <div className="input-box w-100 mb-2 position-relative">
//         <p>Address 1</p>
//         <div className="w-100 d-flex">
//           <input
//             type="address"
//             className="py-2"
//             placeholder="No. 40/61, Rajan Nagar, 2nd Street, Lakshmipuram"
//           />
//           {/* <DatePicker
//           selected={startDate}
//           onChange={(date) => setStartDate(date)}
//           placeholderText="No. 40/61, Rajan Nagar, 2nd Street, Lakshmipuram"
//         />
//         <DateRangeOutlined className="primary1 fs-22 fs-sm-23 fs-md-24 fs-xl-25 position-absolute date-align" /> */}
//         </div>
//       </div>
//       <div className="input-box wi-100 w-md-100 mb-2">
//         <p>Address 1</p>
//         <input
//           type="address"
//           placeholder="No. 40/61, Rajan Nagar, 2nd Street, Lakshmipuram"
//           className="py-4"
//         />
//       </div>
//       {/* <div className="input-box wi-100 w-md-100 mb-2">
//       <p className="text-dark">
//         Upload the logo you'd like to put on your product
//       </p>
//     </div>
//     <div className="d-flex flex-wrap flex-column flex-md-row ac-jb delivery_product bg-lt-blue p-2 px-md-4 px-lg-5 py-md-3 w-100">
//       <div className="file-upload-input d-flex ac-js">
//         <img src={delivery_product} alt="" />
//         <div className="text-start ps-3">
//           <h6 className="primary">
//             Upload Vector file for the best quality and faster turnaround
//           </h6>
//           <p className="priamry1">
//             The file should end in: .svg, .eps, .ai, .pdf
//           </p>
//         </div>
//       </div>
//       <h6 className="mt-2 mt-md-0 mb-0">Learn more</h6>
//     </div> */}
//       <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-4 mt-lg-4">
//         {/* <div className="wi-100 w-sm-47 d-flex ac-js mb-3 mb-sm-2">
//         <label className="w-100 position-relative">
//           <input onChange={fileChange} type="file" id="images" required />
//           {uploadImage ? (
//             <div className="prev-img">
//               <img src={uploadImage} alt="" />
//             </div>
//           ) : (
//             <div className="log-upload-box position-absolute">
//               <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
//                 Primary Logo
//               </p>
//               <img src={cloudUpload} className="" alt="" />
//               <p className="text-dark text-center fs-13 fs-md-14">
//                 Drag and drop to upload or Click <br />
//                 here to upload
//               </p>
//             </div>
//           )}
//         </label>
//       </div>
//       <div className="wi-100 w-sm-47 d-flex ac-js mb-3 mb-sm-2">
//         <label className="w-100 position-relative">
//           <input onChange={fileChange} type="file" id="images" required />
//           {uploadImage ? (
//             <div className="prev-img">
//               <img src={uploadImage} alt="" />
//             </div>
//           ) : (
//             <div className="log-upload-box position-absolute">
//               <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
//                 Secondary Logo
//               </p>
//               <img src={cloudUpload} className="" alt="" />
//               <p className="text-dark text-center fs-13 fs-md-14">
//                 Drag and drop to upload or Click
//                 <br /> here to upload
//               </p>
//             </div>
//           )}
//         </label>
//       </div> */}

//         <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
//           <div className="input-box wi-100 w-md-50 mb-2">
//             <p>City</p>
//             <input type="name" className="py-2" placeholder="Chennai" />
//           </div>
//           <div className="input-box wi-100 w-md-50 mb-2">
//             <p>State</p>
//             <input type="text" className="py-2" placeholder="Tamilnadu" />
//           </div>
//         </div>
//         <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
//           <div className="input-box wi-100 w-md-50 mb-2">
//             <p>Landmark (optional)</p>
//             <input type="name" className="py-2" placeholder="Fedral Bank" />
//           </div>
//           <div className="input-box wi-100 w-md-50 mb-2">
//             <p>Alternate Phone (optional)</p>
//             <input type="number" className="py-2" placeholder="98765 43210" />
//           </div>
//         </div>
//         <div className="d-flex align-items-center  w-100 mb-2 ">
//         <div>
//           <button
//             onClick={showContactHandler}
//             className="get-start-btn border-0 mb-2 w-auto px-3 px-md-4  "
//           >
//             Save and Deliver Here
//           </button>
//           </div>
//           <div>
//           <a className="primary1 fs-16 f2 border-0 mt-4 w-auto px-3 px-md-4">Cancel</a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default TellProject;
