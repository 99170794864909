import React from "react";
import WhatsNew from "./WhatsNew";
import LeftBannerCom from "../Home/Banner/LeftBannerCom";

const DashboardDetail = () => {
  return (
    <div className="d-flex flex-column flex-lg-row">
      <LeftBannerCom />
      <WhatsNew />
    </div>
  );
};

export default DashboardDetail;
