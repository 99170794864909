// @ts-nocheck
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ErrorIcon from "@mui/icons-material/Error";

const ContactDetails = ({
  showContactHandler,
  handleContinue,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  number,
  setNumber,
  organization,
  setOrganization,
  firstNameErr,
  lastNameErr,
  emailErr,
  numberErr,
  organizationErr,
  submitHandle,
  btn,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const Token = localStorage.getItem("token");

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  return (
    <>
      <div className="d-flex ac-jb">
        <h4 className="sect-title">1. Contact Details</h4>
        {/* <button
          onClick={showContactHandler}
          className="cust-btn f3 edit-prev fs-13 fs-md-16 fs-lg-18 d-flex ac-jc"
        >
          <ModeEditIcon className="fs-12 fs-md-16 fs-lg-18 me-1" />
          Edit Previous
        </button> */}
      </div>
      <div className="d-flex flex-wrap">
        <div className="input-box wi-100 w-md-50 mb-2">
          <p>First Name*</p>
          <input
            className="py-2"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          {firstName?.length == 0 && firstNameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>
        <div className="input-box wi-100 w-md-50 mb-2">
          <p>Last Name*</p>
          <input
            className="py-2"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          {lastName?.length == 0 && lastNameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Last Name</p>
            </div>
          )}
        </div>
        <div className="input-box wi-100 w-md-50 mb-2">
          <p>Email Address*</p>
          <input
            className="py-2"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            disabled={Token ? true : false}
          />
          {email?.length == 0 && emailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Email</p>
            </div>
          )}
          {validEmail.test(email) == false && email?.length > 0 && emailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Valid Email</p>
            </div>
          )}
        </div>
        <div className="input-box wi-100 w-md-50 mb-2">
          <p>Contact Number*</p>
          <PhoneInput
            country={"in"}
            value={number}
            onChange={(phone) => setNumber(phone)}
          />
          {number?.length == 0 && numberErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Contact Number</p>
            </div>
          )}
          {/* <input type="number" className="py-2" /> */}
        </div>
        <div className="input-box wi-100 w-md-50 mb-2">
          <p>Organization Name*</p>
          <input
            className="py-2"
            onChange={(e) => setOrganization(e.target.value)}
            value={organization}
          />
          {organization?.length == 0 && organizationErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Organization Name</p>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-0">
        <button
          onClick={() => handleContinue()}
          // onClick={() => submitHandle()}
          className={`get-start-btn border-0 mb-2 w-auto px-3 px-md-4 ${
            btn && "opacity-50"
          }`}
          disabled={btn}
        >
          Continue
        </button>
      </div>
      <p className="parag err fs-16">
        You will receive proofs and a quote before finailizing order*
      </p>
    </>
  );
};

export default ContactDetails;
