import React, { useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { usePresetPackViewMutation } from "../../redux/api/api";
import { useNavigate } from "react-router-dom";
import ProductPopupComponent from "./Preset/ProductPopupComponent";

const ProductCarousel = ({ eventList, type, containerRef }) => {
  const detailRefs = useRef({}); // Store refs for each item

  const navigate = useNavigate();

  //RKT
  const [presetPackApi] = usePresetPackViewMutation();
  const [packlist, setPackList] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [itemId, setItemId] = useState("");

  // console.log('eventList', eventList);


  // Dummy products data
  const products = [
    {
      id: 1,
      img: "https://via.placeholder.com/150x150.png?text=Pack",
      tit: '1dInsert Cards by Moo - 5" x 7',
      start: 1.56,
    },
    {
      id: 2,
      img: "https://via.placeholder.com/150x150.png?text=Water+Bottle",
      tit: 's1dInsert Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 3,
      img: "https://via.placeholder.com/150x150.png?text=Pack",
      tit: 'Insert Cards by Moo - 5" x 7',
      start: 1.56,
    },
    {
      id: 4,
      img: "https://via.placeholder.com/150x150.png?text=Water+Bottle",
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 5,
      img: "https://via.placeholder.com/150x150.png?text=Pack",
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 6,
      img: "https://via.placeholder.com/150x150.png?text=Water+Bottle",
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
  ];

  // OwlCarousel options
  const carouselOptions = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 2 },
      600: { items: 5 },
      1000: { items: 6 },
      1400: { items: 8 },
    },
  };
  const carouselProducts = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 },
      1400: { items: 6 },
    },
  };

  // Handle click to scroll to the exact item detail
  const handleClick = (id) => {
    const element = detailRefs.current[id];
    // console.log('element', element);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };


  const getPresetPackView = (id) => {
    const formData = new FormData();
    formData.append("preset_pack_id ", id);
    presetPackApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        const temp = [];
        temp?.push(res?.pack);
        const image = [];
        temp?.map((item) => {
          image.push(item?.event_category?.image_url);
          item?.preset_product?.map((img) => {
            image.push(img?.product?.defaultImage);
          });
          // list?.push(()
        });

        const list = {
          ...res?.pack,
          images: image,
        };

        // list?.push({
        //   ...item,
        //   images: image,
        // });

        setPackList(list);
        setShowPopup(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showPopupHander = () => {
    setShowPopup(!showPopup);
    // console.log("099");
  };


  return (
    <div style={{ padding: "20px" }}>
      {showPopup && (
        <ProductPopupComponent
          showPopupHander={showPopupHander}
          itemId={itemId}
          packlist={packlist}
        />
      )}
      {/* Owl Carousel */}

      <OwlCarousel className="owl-theme" {...carouselOptions}>
        {eventList?.map((product) => (
          <div className="owl-kits">
            <div
              key={product?.id}
              className="item"
              onClick={() => handleClick(product?.id)}
              style={{
                cursor: "pointer",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                textAlign: "center",
                objectFit: "contain",
                objectPosition: "center",
                display: "inline-block",
                height: "200px",
                width: "100%",
              }}
            >
              <img
                src={product?.image_url}
                alt={product?.name}
                style={{ width: "100%", height: "77%", borderRadius: "8px" }}
              />
              <h4 className="f4 fs-12 fs-md-12 fs-lg-16 text-black mt-2 mb-2">{product?.name}</h4>
            </div>
          </div>
        ))}
      </OwlCarousel>

      {/* All Product Details */}
      <div style={{ marginTop: "20px" }}>
        {eventList?.map((product) => {
          return (
            <div className="pre-products"
              key={product?.id}
              ref={(el) => (detailRefs.current[product.id] = el)}
              style={{
                marginBottom: "20px",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                background: "#f9f9f9",
              }}
            >
              <div>
                <h4 className="f4 fs-12 fs-sm-16 fs-md-18 fs-xl-20 fs-xxl-22">{product?.name}</h4>
                <div className="owl-prod">
                  <OwlCarousel className=" mt-3" {...carouselProducts}>
                    {product?.presetPack?.map((item, inx) => {
                      return (
                        <div className="owl-products">
                          <div
                            style={{ padding: "10px" }}
                            key={inx}
                            onClick={() => {
                              if (type == "home") {
                                navigate("/products/preset-pack");
                              } else {
                                getPresetPackView(item?.id);
                              }
                            }}
                          >
                            <img
                              src={item?.preset_image_url?.image_url}
                              alt={item?.title}
                              className="prod-owl-img"
                              style={{
                                width: "100%",
                                marginBottom: "10px",
                                borderRadius: "8px",
                                
                              }}
                            />
                            <h4 className="f2 fs-12 fs-sm-14 fs-md-15 fs-xl-16">
                              {item?.title}
                            </h4>
                            <p style={{ color: "#555" }}>Starting at $ {item?.price}</p>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ProductCarousel;