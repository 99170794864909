import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { closeIcon, discountIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const RequiredSummary = ({
  OpenSummery,
  showClickHandler,
  showUpdate,
  openSummeryHandler,
  list,
  viewBreakdown,
}) => {
  const location = useLocation();
  const navi = useNavigate();
  const [popup, setPopup] = useState(false);

  const totalSum = (sub_total, data) => {
    let amount = +sub_total;
    // if (list?.storing_type == 1 || list?.storing_type == 2) {
    amount += +data?.shipping_storage_amount;
    // }
    amount = amount - +data?.discount_amount;

    if (data?.fixed_charge_total) {
      let fixed_charge_total =
        +data?.fixed_charge_total - +data?.fixed_charge_tax;
      amount += +fixed_charge_total;
    }

    return amount
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const totalGstSum = (estimate_tax, shipping_tax_amount, fixed_charge_tax) => {
    let amount = 0;

    if (estimate_tax) {
      amount += +estimate_tax;
    }

    if (shipping_tax_amount) {
      amount += +shipping_tax_amount;
    }

    if (fixed_charge_tax) {
      amount += +fixed_charge_tax;
    }

    return amount
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div
      className="req-summary"
      style={{ right: OpenSummery ? "0px" : "-400px" }}
    >
      <button
        style={
          OpenSummery
            ? {
                right: "270px",
                position: "fixed",
                top: "102px",
                zIndex: "1",
              }
            : {
                right: "0px",
                position: "fixed",
                top: "102px",
                zIndex: "1",
              }
        }
        onClick={openSummeryHandler}
        className="cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17 close-btn11"
      >
        <CloseIcon />
      </button>
      <h4 className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-20 ms-2 primary">
        Request Summary
      </h4>
      <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-3">
        {list?.project_code}
      </h4>
      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-14 ms-2 primary1 mt-2">
        Date Requested : {list?.start_date}
      </p>
      {/* <h4 className="f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17 ms-2 text-dark mt-2">
        The Triblend Unisex Tee
      </h4>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          $18.15 X 100
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          $1815
        </p>
      </div> */}
      {/* <button
        onClick={() => showUpdate(true)}
        className="cust-btn f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-primary mt-0"
      >
        Update Quantities
        <ArrowForwardIosIcon className="cust-btn f2 fs-12 fs-lg-15 ms-2 text-primary mt-0" />
      </button> */}
      <div className="d-flex w-100 ac-jb mt-0 memb-dics">
        <div className="d-flex w-100 ac-js mt-0">
          <img src={discountIcon} alt="" className="w-10" />
          <p className="f2 fs-13 fs-sm-13 fs-md-14 ms-1 ps-0 text-dark mt-0 mb-0 ">
            MemberShip Discounts
          </p>
        </div>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 ms-0 ps-0 text-dark mt-0  mb-0">
          ₹{list?.discount_amount ? commaValue(list?.discount_amount) : 0}
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-3">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Subtotal
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.sub_total ? commaValue(list?.sub_total) : "0"}
        </p>
      </div>

      {list?.discount_type == 2 && (
        <div className="d-flex w-100 ac-jb mt-0">
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
            After Discount
          </p>
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
            ₹
            {list?.sub_total && list?.discount_amount
              ? commaValue(+list?.sub_total - +list?.discount_amount)
              : list?.sub_total
              ? commaValue(list?.sub_total)
              : "0"}
          </p>
        </div>
      )}

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Fixed Charge Total
          <ErrorOutlineIcon
            onClick={() => {
              setPopup(true);
            }}
            className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-2 mb-0 pb-0 primary1 mt-0 pointer"
          />
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹
          {list?.fixed_charge_total && list?.fixed_charge_tax
            ? commaValue(+list?.fixed_charge_total - +list?.fixed_charge_tax)
            : list?.fixed_charge_total
            ? commaValue(+list?.fixed_charge_total)
            : "0"}
        </p>
      </div>
      {popup && (
        <div className="tl-update-qty">
          <div
            className="back-update-qty"
            style={{ display: showUpdate ? "block" : "none" }}
          ></div>
          <div
            className="update-qty update-qty-custom"
            style={{ right: showUpdate ? "-30%" : "-100%" }}
          >
            <button
              onClick={() => {
                setPopup(false);
              }}
              className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex ac-jb mt-2 mt-lg-4">
              <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
                Fixed Charge Total
              </h6>
            </div>
            <div className="btm-scroll pt-3 ">
              {list?.fixed_charges?.map((item, ind) => {
                return (
                  <div
                    className="d-flex gap-5 mt-2 mt-lg-4 w-100 mx-3"
                    key={ind}
                  >
                    <p className="mb-0 primary1 mt-0 text-nowrap mt-3 f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 w-15">
                      {item?.fixed_charge?.name}
                    </p>
                    <p className="mb-0 mt-3 f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16">
                      {+item?.amount - +item?.tax_amount}
                    </p>
                    <p className="mb-0 mt-3 f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16">
                      {+item?.amount}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {(list?.storing_type == 1 || list?.storing_type == 2) && (
        <div className="d-flex w-100 ac-jb mt-0">
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 text-nowrap">
            Shipping Amount
            <ErrorOutlineIcon
              className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-1 mb-0 pb-0 primary1 mt-0 "
              onClick={() => viewBreakdown(list)}
              role="button"
            />
          </p>
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
            ₹
            {list?.shipping_storage_amount
              ? commaValue(list?.shipping_storage_amount)
              : "0"}
          </p>
        </div>
      )}

      {list?.storing_type == 3 && (
        <div
          className="d-flex w-100 ac-jb mt-0"
          onClick={() => viewBreakdown(list)}
          role="button"
        >
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
            Storage
            <ErrorOutlineIcon className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-2 mb-0 pb-0 primary1 mt-0 " />
          </p>

          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0 ">
            ₹
            {list?.shipping_storage_amount
              ? commaValue(list?.shipping_storage_amount)
              : "0"}
          </p>
        </div>
      )}

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Total
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0">
          ₹{totalSum(+list?.sub_total, list)}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Products GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.estimate_tax ? commaValue(list?.estimate_tax) : "0"}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Shipping GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.estimate_tax ? commaValue(list?.shipping_tax_amount) : "0"}
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Fixed Charge GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.fixed_charge_tax ? commaValue(list?.fixed_charge_tax) : "0"}
        </p>
      </div>

      {/* <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Rush Production
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-30">
          $0.00
        </p>

      </div> */}
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Total GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0">
          ₹
          {totalGstSum(
            +list?.estimate_tax,
            +list?.shipping_tax_amount,
            +list?.fixed_charge_tax
          )}
        </p>
      </div>

      {/* +
              list?.fixed_charge_tax
              ? +list?.fixed_charge_tax
              : 0 */}

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          Final Amount
        </p>
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          ₹{list?.total ? commaValue(list?.total) : ""}
        </p>
      </div>
      {location?.state?.status <= 6 && (
        <button
          onClick={() => navi("/select-product", { state: list })}
          className="w-100 bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2"
        >
          Continue
        </button>
      )}
    </div>
  );
};

export default RequiredSummary;
