import React from "react";

const ServicesComp = ({ img, title, desc, isOdd }) => {
  return (
    <div
      className={`d-md-flex w-80 mt-5 w-md-70 mx-auto mx-md-start jsb py-3 ${
        isOdd ? 'flex-md-row-reverse' : ''
      }`} // Reverse row for odd items on medium screens and above
    >
      <div className="d-flex w-90 w-md-45">
        <div className="text-custom-align w-100">
          <h5 className="f4 fs-18 fs-md-21 fs-md-25 fs-xl-28 w-100 text-dark">
            {title}
          </h5>
          <p className="mb-3 mb-md-0 primary1 f2 fs-13 fs-md-14 fs-md-15 fs-xl-16">
            {desc}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center w-70 m-md-0  m-md-start w-md-45 rounded-5 overflow-hide m-auto">
        <img src={img} className="w-100 h-100 object-fit-contain" alt={title} />
      </div>
    </div>
  );
};


export default ServicesComp;
