// @ts-nocheck
import React from "react";
import { closeIcon, product1 } from "../../assets/img";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import { height } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useState } from "react";
import { selectQuantityList } from "../../redux/api/DummyJson";

const ProductCart = ({
  productCartOpen,
  tick,
  pack,
  addProductHandler,
  cortList,
  priceList,
  setShowPopup,
  deleteCart,
  getProductView,
  packs,
  totalPerPrice,
  totalPrice,
  cartData,
  showoption,
  deleteBtn,
  customQty,
  noOfPack,
  setNoOfPack,
  noOfPackErr,
  setShowOption,
  cartShow,
}) => {
  // console.log(pack);
  const navigate = useNavigate();
  const optionClick = () => {
    setShowOption(true);
  };

  const productHandler = () => {
    if (pack == "build") {
      navigate("/mock-request", { pack: "build" });
    } else {
      navigate("/mock-request", { pack: "bulk" });
    }
  };
  // console.log("cortList", cortList);
  return (
    <div
      // className={`${tick?.length > 0 ? "show-cart" : "hide-cart"} prod-cart`}
      className={`${tick?.length > 0 ? "show-cart" : "hide-cart"} prod-cart ${
        cartShow && "d-none"
      } `}
    >
      <div className="position-relative h-100">
        <div className="d-flex ac-jb mb-4">
          <h4 className="mb-0">Your Product</h4>

          <img
            src={closeIcon}
            alt="Close"
            role="button"
            onClick={() => productCartOpen()}
            className="cancel-btn pointer"
          />
        </div>
        <div
          className={`${
            pack == "build" ? "build-list-cart" : "bulk-list-cart"
          }`}
        >
          {showoption && (
            <div>
              {cortList?.map((item, ind) => {
                return (
                  <div className="prod-cart-list w-100 d-flex ac-jb" key={ind}>
                    <div
                      className=" d-flex ac-js"
                      onClick={() => {
                        setShowPopup();
                        getProductView(item?.product?.id, item?.id, item);
                      }}
                      role={"button"}
                    >
                      <div className="img-box">
                        <img
                          src={
                            item?.selected_image_url
                              ? item?.selected_image_url
                              : product1
                          }
                          alt=""
                        />
                      </div>
                      <div className="">
                        <h5>{item?.product?.name}</h5>
                        <p>Price starting at ₹ {item?.total_price}</p>
                        <h6>Quantity: {item?.qty}/Kit</h6>
                      </div>
                    </div>
                    <button
                      className={`cust-btn ${deleteBtn && "opacity-50"}`}
                      // onClick={() => addProductHandler("", "", item, ind)}
                      onClick={() => {
                        deleteCart(item?.id, item?.product?.id);
                      }}
                      disabled={deleteBtn}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {!showoption && (
            <div>
              <div className="d-flex ac-jb">
                <p className="f3 fs-11 fs-sm-12 fs-md-13 fs-lg-14 text-dark mt-0 mb-0">
                  Select Quantity
                </p>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => setShowOption(!showoption)}
                >
                  <KeyboardArrowUp />
                </button>
              </div>
              <p className="f2 fs-11 fs-sm-13 fs-md-14 fs-lg-10 primary1 mt-3 mb-3 text-center">
                The more you order, the more you save
              </p>
              {/* {priceList?.map((item, index) => { */}
              {/* return ( */}
              <div className="" onClick={optionClick}>
                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(25)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    25
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["25"]}
                  </p>
                </div>

                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(50)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    50
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["50"]}
                  </p>
                </div>

                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(100)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    100
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["100"]}
                  </p>
                </div>
                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(150)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    150
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["150"]}
                  </p>
                </div>
                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(250)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    250
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["250"]}
                  </p>
                </div>
                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(500)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    500
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["500"]}
                  </p>
                </div>
                <div
                  className="d-flex ac-jb py-2 px-1 select-list"
                  role="button"
                  onClick={() => customQty(1000)}
                >
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    1000
                  </p>
                  <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                    ₹{priceList?.["1000"]}
                  </p>
                </div>
              </div>
              {/* );
              })} */}
              <div className="d-flex select_input ac-jb mt-3 mb-3 h-100 w-100 py-2 px-1">
                <input
                  type="number"
                  className="bg-transparent border-0 h-100 w-100 f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 ps-3"
                  placeholder="Custom quantity"
                  onChange={(e) => setNoOfPack(e.target.value)}
                  value={noOfPack}
                />
                <button
                  className="my-0 border-0 bg-transparent f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary text-start"
                  onClick={() => customQty()}
                >
                  Enter
                </button>
              </div>
              {noOfPack?.length == 0 && noOfPackErr && (
                <div className="d-flex gap-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Custom quantity</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="build-btm-cart">
          {showoption && (
            <div className="d-flex ac-jb mt-1">
              <h6 className="mb-0">
                Quantity
                <span className="f3 fs-9 fs-sm-10 fs-md-11 fs-lg-12 primary1 ms-1">
                  (Minimum 25)
                </span>
                {" " + packs}
              </h6>
              {/* <select className="cust-btn f4" name="" id="">
              <option value="25
              ">25</option>
              <option value="100">100</option>
            </select> */}
              <button
                className="border-0 bg-transparent"
                onClick={() => setShowOption(!showoption)}
              >
                <KeyboardArrowDownOutlined />
              </button>
            </div>
          )}
          <div className="d-flex ac-jb mb-2">
            <h5 className="mb-0">Price per Kit</h5>
            <h5 className="">₹{totalPerPrice}</h5>
          </div>
          <div className="d-flex ac-jb mb-2">
            <h5 className="mt-0">
              Total Estimate
              <span className="icon-pop1 ms-1">
                <ErrorOutlineIcon
                  style={{ cursor: "pointer" }}
                  className="f3 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-lg-15 mb-0 pb-0 primary1 mt-0 text-center"
                />
                <div className="icon-pop2 position-absolute justify-content-center align-items-center d-flex">
                  <p className="f3 mb-0 pb-0 text-dark mt-0 text-center px-3">
                    Total est doesn't include taxes and shipping fees
                  </p>
                </div>
              </span>
            </h5>
            <h5 className="">₹{totalPrice}</h5>
          </div>
          <div className="mb-5">
            <button
              onClick={productHandler}
              className="cust-btn bg-primar get-start-btn mx-auto mb-5 mt-0 w-100"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCart;
