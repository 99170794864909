// @ts-nocheck
import { Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HomeTab from "../../Home/HomeTab";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OwlCarousel from "react-owl-carousel";
import { row2, row3 } from "../../../redux/api/constants";
import {
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  noLogo,
  products1,
  products2,
  products3,
  products4,
  robotIcon,
  Searcher,
} from "../../../assets/img";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PresetListTabcomp from "./PresetListTabcomp";
import {
  useColorListMutation,
  useEventListMutation,
  useLazyTagsfilterQuery,
  usePresetPackListMutation,
} from "../../../redux/api/api";
import DoneIcon from "@mui/icons-material/Done";
import { ArrowDropDown } from "@mui/icons-material";
import RangeSet from "../../Range/RangeSet";
import CachedIcon from "@mui/icons-material/Cached";
import ProductCarousel from "../ProductCarousel";

const RightProducts = ({ showPopupHander }) => {
  const carouselRef = useRef(null);
  const containerRef = useRef([]);
  const [scrollId, setScrollId] = useState("");
  const [eventList, setEventList] = useState([]);
  const [presetList, setPresetList] = useState([]);
  const [color, setColor] = useState(false);
  const [colorId, setColorId] = useState("");
  const [normal, setNormal] = useState([]);
  const [event, setEvent] = useState([]);
  const [icon, setIcon] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [normalid, setnormalid] = useState();
  const [eventid, setEventid] = useState();
  const [iconid, setIconid] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [drag, setDrag] = useState(false);
  const [normalids, setNormalId] = useState([]);
  const [values, setValues] = useState([]);
  const [priceSort, SetPriceSort] = useState("");
  const [search, setSearch] = useState("");
  const [max, setMax] = useState(0);

  const handleClick = (event) => {
    console.log(event, "event");
    event.preventDefault(); // Prevent the default behavior of the anchor link
    const targetId = event.currentTarget.getAttribute("href").slice(1); // Extract target ID from href
    const targetElement = document.getElementById(targetId); // Find the target element
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" }); // Scroll to the target element
    }
  };
  //APK
  const [eventListApi] = useEventListMutation();
  // const [presetListApi]= usePresetPackListMutation();
  const [tagsListApi] = useLazyTagsfilterQuery();
  const [colorListApi] = useColorListMutation();

  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setNormal(res?.normalTag);
        setEvent(res?.eventTag);
        setIcon(res?.iconTag);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getColorsList = () => {
    colorListApi()
      .unwrap()
      .then((res) => {
        setColorList(res?.colors);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTagsList();
    getColorsList();
  }, []);

  const getEvent = (colorId, event, price_value = []) => {
    const formData = new FormData();
    if (colorId) {
      formData.append("color_id", colorId);
    }
    if (event) {
      formData.append("search", event);
    }
    if (price_value?.[0] && price_value?.[1]) {
      formData.append("start_price", price_value[0]);
      formData.append("end_price", price_value[1]);
      setValues(price_value);
    }
    if (normalids?.length > 0) {
      normalids?.map((item, ind) => {
        formData.append(`tag_id[${ind}]`, item);
      });
    }
    priceSort == "high"
      ? formData.append("high_to_low", 1)
      : formData.append("low_to_high", 1);

    setSearch(event);
    setColorId(colorId);

    eventListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res);
        setEventList(res?.event_categories);

        if (res?.max_price) {
          setMax(Math.round(+res?.max_price));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvent(colorId, search, values);
  }, [normalids, priceSort]);

  const ColorDropdown = () => {
    setColor(!color);
  };
  const handleColorClick = (id) => {
    setColor(!color);
  };
  // console.log("eventList", eventList);

  const tags = () => {
    let temp = [];
    if (normalid) {
      temp.push(normalid);
    }
    if (iconid) {
      temp.push(iconid);
    }
    if (eventid) {
      temp.push(eventid);
    }
    //  }else if(eventid){
    //  }else{
    //  }
    setNormalId(temp);
  };
  useEffect(() => {
    tags();
  }, [normalid, eventid, iconid]);

  const lowpriceHandel = () => {
    SetPriceSort("low");
    setShowFilter(!showFilter);
  };
  const highpriceHandel = () => {
    SetPriceSort("high");
    setShowFilter(!showFilter);
  };
  const HandledDrag = () => {
    setDrag(!drag);
  };
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const refreshHandel = () => {
    SetPriceSort("low");
    setSearch("");
    setColorId("");
    setnormalid("");
    setEventid("");
    setIconid("");
    setValues([]);
    getEvent("", "", "");
  };
  return (
    <div className="rit-prod pack-prod mt-0 text-center">
      <div className="w-80 w-lg-70 w-xl-50 mt-2 mt-lg-4 mx-auto mb-2">
        <h4 className="sect-title mb-2 mb-lg-1">Pre-set Kits</h4>
        <p className="blue fs-20 f3">How about this as an alternative?</p>
        <p className="parag disc text-center">
          Easy is not easy enough. We’ve made it easier! We’ve done all the
          ground-work to curate kits with extreme thought and care. Our preset
          kits are the perfect shortcut to all your gifting nightmares.
        </p>
      </div>
      <div className="d-flex w-90 ac-jc m-auto  mt-5">
        {/* <button className="iconbg" onClick={() => carouselRef.current.prev()}>
          <ArrowBackIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
        </button> */}
        <div className="w-100">
          <ProductCarousel eventList={eventList} />
        </div>
        {/* 
        <button className="iconbg" onClick={() => carouselRef.current.next()}>
          <ArrowForwardIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
        </button> */}
      </div>
    </div>
  );
};

export default RightProducts;
