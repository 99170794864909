// @ts-nocheck
import React from "react";
import { logist, noLogo, product1, productd } from "../../assets/img";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const ProductList = ({
  showPopupHander,
  addProductHandler,
  tick,
  showCartHandler,
  setSearch,
  setOrder,
  setRows,
  rows,
  search,
  order,
  setUserList,
  userList,
  btnChange,
  page,
}) => {
  // console.log(rows, "rows");
  // console.log("btnChange", btnChange);
  const navigate = useNavigate();

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="login-product d-flex flex-wrap">
      {userList?.map((item, ind) => {
        // console.log(item?.price, "item")
        // console.log(
        //   item?.project_request?.project_request_id, "hj"
        // )
        const first = item?.projectPresentationPrice;
        // console.log(first, "presentation")
        const firstTotal = first && first.length > 0 ? first[0].total : null;
        const lastTotal =
          first && first.length > 1 ? first[first.length - 1].total : null;
        return (
          // <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3">
          <div
            className="py-2 px-1 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-box-tl "
            key={ind}
          >
            <div className="prod-list-box">
              <div className="d-flex ac-jb">
                <p
                  className={`${
                    btnChange == 2
                      ? "bg-approved_pro text-white"
                      : item?.project?.status == 4 &&
                        item?.project?.presentation_ready == 0
                      ? "bg-ready_pro text-white"
                      : item?.project?.presentation_ready == 0
                      ? "bg-lt-design design"
                      : item?.project?.presentation_ready == 1
                      ? "bg-approved_pro text-white"
                      : item?.project?.estimation_ready == 1
                      ? "bg-approved_pro text-white"
                      : "bg-lt-design design"
                  }  fs-11 fs-md-12 f2 rounded rounded-2 mb-0 py-1 px-2`}
                >
                  {btnChange == 2
                    ? "Approved By Me"
                    : item?.project?.status == 4 &&
                      item?.project?.presentation_ready == 0
                    ? "Mockup Ready"
                    : item?.project?.presentation_ready == 0
                    ? "Pending Mockup"
                    : item?.project?.presentation_ready == 1
                    ? "Approved By Me"
                    : item?.project?.estimation_ready == 1
                    ? "Approved By Me"
                    : "Pending Mockup"}
                </p>
                {/* <div className="d-flex align-items-center">
                  <img className="logist" src={logist} alt="" />
                  <p className="parag sec mb-0 ms-2 f4">
                    {" "}
                    {item?.req_no_of_packs}
                  </p>
                </div> */}
              </div>

              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img pt-2 mb-2"
              >
                <img
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                  // src={
                  //   item?.project?.project_request?.images?.length > 0
                  //     ? item?.project?.project_request?.images?.[
                  //         item?.project?.project_request?.images?.length - 1
                  //       ]?.image_url
                  //     : noLogo
                  // }
                  src={item?.display_img ? item?.display_img : product1}
                  alt=""
                />{" "}
              </button>
              <div className="btm-prod">
                <h4 className="f2 fs-14 fs-md-15 fs-lg-16">
                  {item?.project_request?.project_request_id
                    ? item?.project_request?.project_request_id
                    : item?.project?.project_request
                    ? item?.project?.project_request?.project_request_id
                    : item?.project_request_id}
                </h4>
                <h3 className="f2 fs-14 fs-md-15 fs-lg-16">
                  {btnChange == 2 ? item?.project_code : item?.product?.name}
                </h3>
                {/* <p className="primary1 f2 mb-0 pb-0">
                    {`₹${firstTotal}`} 
                    {lastTotal !== null ? ` - ₹${lastTotal}` : ''} 
                    {item?.total_amount !== undefined ? ` || ₹${item.total_amount}` : ''}
                  </p> */}
                <p className="primary1 f2 mb-0 pb-0">
                  {/* {firstTotal !== null ? `₹${commaValue(firstTotal)}` : ""}
                  {lastTotal !== null ? ` - ₹${commaValue(lastTotal)}` : ""} */}
                  {item?.project?.total_amount
                    ? `  ₹${commaValue(item?.project?.total_amount)}`
                    : item?.total_amount
                    ? `  ₹${commaValue(item?.total_amount)}`
                    : ""}
                </p>

                <div className="d-flex justify-content-between mt-2">
                  {btnChange == 2 ? (
                    <div
                      onClick={() => {
                        navigate("/my-product-View_Proof_detail", {
                          state: item,
                        });
                      }}
                      className="cust-btn f4 fs-14 fs-md-15 primary pointer d-flex ac-jc"
                    >
                      <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
                      View Proof
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (item?.project) {
                          if (item?.project?.status === 4) {
                            navigate("/my-product-View_Proof_detail", {
                              state: item,
                            });
                          } else {
                            navigate("/my-product-detail", { state: item });
                          }
                          // else {
                          //   navigate("/my-order");
                          // }
                          // navigate("/my-product-detail", { state: item });
                        } else {
                          navigate("/my-product-detail", { state: item });
                        }
                      }}
                      className="cust-btn f4 fs-14 fs-md-15 primary pointer d-flex ac-jc"
                    >
                      <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
                      {item?.project?.status === 4 ? "View Proof" : "View"}
                    </div>
                  )}

                  {page == "Products Library" && (
                    <button onClick={() => showCartHandler(1)} className="plus">
                      {tick ? <CheckIcon /> : <AddIcon />}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          // </div>
        );
      })}
    </div>
  );
};

export default ProductList;
