// @ts-nocheck
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { logo2, logo3, logo4, logo5, logo6, logo7 } from "../../assets/img";
import { row2 } from "../../redux/api/constants";

const ServingFor = () => {
  return (
    <div className="serving-for text-center">
      <div className="text-custom-align">
        <h4 class="sect-title ">The MerchHQ Method.</h4>
        <p class="mb-3 mt-0 mb-md-0 primary1 f2 fs-13 fs-md-14 fs-lg-15 fs-xl-16">
          Your Merchandise Journey, Simplified!
        </p>
      </div>
      {/* <div className="btn-align">
            <button class=" get-start-btn border-0 my-0 mx-2 mt-2">
              Start a Project
            </button>
          </div>
       
      <h3>Trusted by</h3>
      <p>100+ Fortune 500s est. 2006</p>
      <div className="logos">
        <OwlCarousel {...row2}>
          <img src={logo5} className="" alt="icon" />
          <img src={logo6} className="" alt="icon" />
          <img src={logo4} className="" alt="icon" />
          <img src={logo3} className="" alt="icon" />
          <img src={logo2} className="" alt="icon" />
          <img src={logo7} className="" alt="icon" />
        </OwlCarousel>
      </div> */}
    </div>
  );
};

export default ServingFor;
